import React, { useState, useEffect, Checkbox } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import style from "./plataforma.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Checkmark } from 'react-checkmark'
import VerticalNavbar from '../../verticalHavbarEmpresa/VeticalNavbar2';
import { Button, Modal, Form, Label, FormGroup, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Estudantes_Candidatados = () => {
    const [estudantes, setEstudantes] = useState([]);
    const [isInterviewModalOpen, setIsInterviewModalOpen] = useState(false);
    const [isConvoqueModalOpen, setIsConvoqueModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [periodoEstagio, setPeriodoEstagio] = useState('');
    const [isCheckedComplemento, setIsCheckedComplemento] = useState(false);
    const [isCheckedLocal, setIsCheckedLocal] = useState(false);
    const [isCheckedObservacao, setIsCheckedObservacao] = useState(false);

    const [selectedEstudantes, setSelectedEstudantes] = useState([]);
    const exibeColunaFaculdade = estudantes.some(estudante => estudante.Faculdade !== "-");

    const [inputVaga, setInputVaga] = useState({ Horas: '', Dia: '', Mes:'',Numero:'', Complemento: '', Rua: '',Cidade: '',Estado: '',Cidade: '',Observacao:''});
    const [inputVaga2, setInputVaga2] = useState({ Horas: '', Dia: '', Mes:'', Link:''});
    const location = useLocation();
    const estagio = location.state?.estagio;
    const EmpresaInfo = JSON.parse(sessionStorage.getItem('EmpresaInfo'));
    const [modal_C, setModal_C] = useState(false);
    const [modal_C2, setModal_C2] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);

    const [aux, setAux] = useState(0); // Inicializa aux como 0

    const toggle_C = () => setModal_C(!modal_C);
    const toggle_C2 = () => setModal_C2(!modal_C2);

    const toggleDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const getStatusClassName = (status) => {
        switch(status) {
            case 'Presença confirmada':
                return style.statusVerde;
            case 'Ausente':
                return style.statusVermelho;
            case 'Aprovado para estágio':
                return style.statusVerde;
            case 'Chamado para entrevista':
                return style.statusAmarelo;
            case 'Inscrito':
                return style.statusAmarelo;
            default:
                return ''; // Sem cor para estados não definidos
        }
    }

    useEffect(() => {
        if (estagio) {
            axios.post('https://linkestagio.com.br/App.php?operation=candidatados-vaga', { idVaga: estagio })
                .then((response) => {
                    setEstudantes(response.data);
                })
                .catch((error) => {
                    console.error('Erro:', error);
                });
        }
    }, [estagio]);

    const handleRadioChange = (event) => {
        setPeriodoEstagio(event.target.value);
    };

    const handleCheckboxOberservacao = async (event) => {
        const isChecked = event.target.checked;
        setIsCheckedObservacao(isChecked);  // Atualiza o estado imediatamente
    }

    const handleCheckboxChangeLocal = async (event) => {
        const isChecked = event.target.checked;
        setIsCheckedLocal(isChecked);  // Atualiza o estado imediatamente
    
        if (isChecked) {
            try {
                const response = await axios.post('https://linkestagio.com.br/App.php?operation=get-localizacao', 
                    { CEP: EmpresaInfo.CEP_Empresa }, 
                    { headers: { 'Content-Type': 'application/json' } }
                );
                setInputVaga({
                    ...inputVaga,
                    Rua: response.data.logradouro,
                    Numero: EmpresaInfo.Numero,
                    Complemento: EmpresaInfo.Complemento,
                    Cidade: response.data.localidade,
                    Bairro: response.data.bairro,
                    Estado: response.data.uf

                });
            } catch (error) {
                console.error('Erro ao buscar dados do CEP:', error);
            }
        } else {
            setInputVaga({
                ...inputVaga,
                Rua: "",
                Numero: "",
                Complemento: "",
                Cidade: "",
                Bairro: "",
                Estado: ""
            });
        }
    };

    // Função para lidar com mudanças no checkbox 'Endereço tem complemento'
    const handleCheckboxChangeComplemento = (event) => {
        setIsCheckedComplemento(event.target.checked);

    };

    const ChamarEntrevista = (event) => {
        event.preventDefault();

        var Texto = "";

        if(periodoEstagio === 'Presencial'){
            console.log(inputVaga.Observacao);
            console.log("inputVaga.Observacao");

            if(inputVaga.Complemento == null || inputVaga.Complemento == ''){
                Texto = `Parabéns! Você foi selecionado a entrevista de estágio da empresa 
                ${EmpresaInfo.Nome_Empresa}, que vai acontecer às 
                ${inputVaga.Horas} horas, do dia
                ${inputVaga.Dia}/
                ${inputVaga.Mes.slice(-2)}/${inputVaga.Mes.slice(0, 4)} no endereço: 
                ${inputVaga.Rua}, Número 
                ${inputVaga.Numero},
                ${inputVaga.Cidade},
                ${inputVaga.Bairro},
                ${inputVaga.Estado}.`;

                if (inputVaga.Observacao != null && inputVaga.Observacao !== '') {
                    Texto += `Obs: ${inputVaga.Observacao}`;
                }


            }else{


                Texto = `Parabéns! Você foi selecionado a entrevista de estágio da empresa 
                ${EmpresaInfo.Nome_Empresa}, que vai acontecer às
                ${inputVaga.Horas} horas, do dia
                ${inputVaga.Dia}/
                ${inputVaga.Mes.slice(-2)}/${inputVaga.Mes.slice(0, 4)} no endereço: 
                ${inputVaga.Rua}, Número 
                ${inputVaga.Numero},
                ${inputVaga.Cidade},
                ${inputVaga.Bairro},
                ${inputVaga.Estado}.`;

                if (inputVaga.Observacao != null && inputVaga.Observacao !== '') {
                    Texto += `Obs: ${inputVaga.Observacao}`;
                }


            }

            
        }else if(periodoEstagio === 'Remoto'){


            Texto = `Parabéns! Você foi selecionado para entrevista de estágio na empresa 
            ${EmpresaInfo.Nome_Empresa}, que vai acontecer às 
            ${inputVaga2.Horas} horas, do dia
            ${inputVaga2.Dia}/
            ${inputVaga2.Mes.slice(-2)}/${inputVaga2.Mes.slice(0, 4)}. Que acontecerá no link:
            ${inputVaga2.Link}.`;

            if (inputVaga.Observacao != null && inputVaga.Observacao !== '') {
                Texto += `Obs: ${inputVaga.Observacao}`;
            }

        }

        toggleInterviewModal()

        setTimeout(() => {
            setAux(1);
            toggle_C();
          }, 450);

        var processo = {};



        if (inputVaga2.Dia === ''){

            processo = {
                Estudantes_Escolhidos: selectedEstudantes,
                idVaga: estagio,
                Texto_email: Texto,
                Data_Processo: inputVaga.Dia + "/" + inputVaga.Mes.slice(-2) + "/" + inputVaga.Mes.slice(0, 4),
                Rua: inputVaga.Rua,
                Numero: inputVaga.Numero,
                Complemento: inputVaga.Complemento,
                Hora_Processo: inputVaga.Horas,
                Tipo_Processo: 1
            };


        }else{

            processo = {
                Estudantes_Escolhidos: selectedEstudantes,
                idVaga: estagio,
                Texto_email: Texto,
                Data_Processo: inputVaga2.Dia + "/" + inputVaga2.Mes.slice(-2) + "/" + inputVaga2.Mes.slice(0, 4),
                Hora_Processo: inputVaga2.Horas,
                Link_Processo: inputVaga2.Link,
                Tipo_Processo: 2
            };


        }


        axios.post('https://linkestagio.com.br/App.php?operation=convocar-estudantes', processo)
        .then((response) => {
        })
          .catch((error) => {
            console.error('Erro de login:', error);
          });
      };

      const CriarContrato = (event) => {
        event.preventDefault();


        toggleConvoqueModal()


        setTimeout(() => {
            setAux(2);
            toggle_C();
          }, 450);
          console.log("aaaa")

        axios.post('https://linkestagio.com.br/App.php?operation=criar-contrato', { Estudantes_Escolhidos: selectedEstudantes, idVaga: estagio, Nome_Empresa: EmpresaInfo.Nome_Empresa,CNPJ_Empresa: EmpresaInfo.CNPJ  })
        .then((response) => {
            console.log(response)
        })
          .catch((error) => {
            console.error('Erro de login:', error);
          });
          console.log("aaaa")

      };

      const RemoverEstudante = (event) => {
        event.preventDefault();
    
    
        toggleDeleteModal()
    
    
        axios.post('https://linkestagio.com.br/App.php?operation=excluir-vaga-estudante', { Estudantes_Escolhidos: selectedEstudantes, idVaga: estagio, Nome_Empresa: EmpresaInfo.Nome_Empresa,CNPJ_Empresa: EmpresaInfo.CNPJ  })
        .then((response) => {
            window.location.reload();
        })
            .catch((error) => {
            console.error('Erro de login:', error);
            });
        };

    
    const toggleInterviewModal = () => {
        setIsInterviewModalOpen(!isInterviewModalOpen);
    };

    const toggleConvoqueModal = () => {
        setIsConvoqueModalOpen(!isConvoqueModalOpen);
    };
    
    const handleChangeCheckbox = (estudanteId) => {
        
        setSelectedEstudantes(prevSelectedEstudantes => {
            if (prevSelectedEstudantes.includes(estudanteId)) {
                return prevSelectedEstudantes.filter(id => id !== estudanteId);
            } else {
                return [...prevSelectedEstudantes, estudanteId];
            }
        });

    };

    function TabelaEstudantesSelecionados({ selectedEstudantes, estudantes }) {
        // Componente da tabela, como definido anteriormente
        return (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Nome completo</th>
                <th scope="col">Curso</th>
                {exibeColunaFaculdade && <th scope="col" className={style.centerText}>Semestre</th>}
              </tr>
            </thead>
            <tbody>
              {estudantes.filter(estudante => selectedEstudantes.includes(estudante.IdEstudante)).map((estudante, index) => (
                <tr key={index}>
                  <td>{estudante["Nome Completo"]}</td>
                  <td>{estudante.Curso}</td>
                  {exibeColunaFaculdade && <td className={style.centerText}>{estudante.Semestre}</td>}
                </tr>
              ))}
              {estudantes.filter(estudante => selectedEstudantes.includes(estudante.IdEstudante)).length === 0 && (
                <tr>
                  <td colSpan="3" className="text-center">Sem estudantes selecionados</td>
                </tr>
              )}
            </tbody>
          </table>
        );
      }


    return (
        <>
        <VerticalNavbar userInfo={EmpresaInfo} />
        <div className={style.configAccount}>
            <h2 className={style.headerSpacing}>Estudantes Cadastrados</h2>
            {isSmallScreen && <p className={style.headerSpacing2}>Para ver mais informações sobre o estudante acesse a página do computador.</p>}

            <div className={style.configAccountNav}>

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" className={style.centerText}>Nome Completo</th>
                        {!isSmallScreen && <th scope="col" className={style.centerText}>Curso</th>}
                        {!isSmallScreen && exibeColunaFaculdade && <th scope="col" className={style.centerText}>Semestre</th>}
                        {!isSmallScreen && exibeColunaFaculdade && <th scope="col" className={style.centerText}>Faculdade</th>}
                        <th scope="col" className={style.centerText}>Currículo</th>
                        <th scope="col" className={style.centerText}>Selecionar</th>
                        <th scope="col" className={style.centerText}>Status</th>

                    </tr>
                </thead>
                <tbody>
                    {estudantes.length > 0 ? (
                        estudantes.map((estudante, index) => (
                            <tr key={index}>
                                <td className={style.centerText}>{estudante["Nome Completo"]}</td>
                                {!isSmallScreen && <td className={style.centerText}>{estudante.Curso}</td>}
                                {!isSmallScreen && exibeColunaFaculdade && <td className={style.centerText}>{estudante.Semestre}</td>}
                                {!isSmallScreen && exibeColunaFaculdade && <td className={style.centerText}>{estudante.Faculdade}</td>}
                                <td className={style.centerText}>
                                    <a href={estudante.Curriculo} style={{ color: '#7e2553' }} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon  icon={faFileAlt} className={style.iconSize} />
                                    </a>
                                </td>
                                <td className={style.centerText}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={() => handleChangeCheckbox(estudante.IdEstudante)} // Estou assumindo que cada estudante tem uma propriedade 'id'
                                    checked={selectedEstudantes.includes(estudante.IdEstudante)} // Isso garante que o checkbox seja marcado se o ID está no array
                                    value={estudante.IdEstudante}

                                />                                
                                </td>
                                <td className={style.centerText}>
                                <span className={(() => {
                                    switch(estudante.Status) {
                                        case 'Presença confirmada':
                                            return style.statusAberta;
                                        case 'Ausente':
                                            return style.statusFechada;
                                        case 'Inscrito':
                                            return style.statusPausada;
                                        case 'Presença confirmada':
                                            return style.statusFechada;
                                        case 'Ausente':
                                            return style.statusVermelho;
                                        case 'Aprovado':
                                            return style.statusAprovado;
                                        case 'Chamado para entrevista':
                                            return style.statusPausada;
                                        case 'Inscrito':
                                            return style.statusPausada;
                                        default:
                                            return style.statusAberta;
                                    }
                                })()}>{estudante.Status}</span>
                            </td>
                        </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center">Sem estudantes cadastrados</td>
                        </tr>
                    )}
                </tbody>
            </table>
            </div>
            {estudantes.length > 0 && (
                                <>
                
                                <div className={style.buttonsRight}>
                                    <button
                                        onClick={toggleDeleteModal}
                                        disabled={selectedEstudantes.length <= 0}
                                        style={{ backgroundColor: '#ff6666', borderColor: '#ff6666', color: 'white' }}
                                        className={`btn ${style.buttonSpacing} ${style.firstButtonRight}`}
                                    >
                                        Excluir Estudantes
                                    </button>
                                    <button onClick={toggleInterviewModal} disabled={selectedEstudantes.length <= 0} style={{ backgroundColor: '#eb6c5c', borderColor: '#eb6c5c', color: 'white' }} className={`btn ${style.buttonSpacing}`}>
                                        Chamar para entrevista
                                    </button>
                                    <button onClick={toggleConvoqueModal} disabled={selectedEstudantes.length <= 0} style={{ backgroundColor: '#7e2553', borderColor: '#7e2553', color: 'white' }} className={`btn ${style.buttonSpacing}`}>
                                        Aprovar para estágio
                                    </button>
                                </div>
                
                                </>
            )}
        </div>

        <Modal isOpen={modal_C} toggle={toggle_C} onHide={toggle_C} centered>
            <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C}>

        </ModalHeader>
          <Checkmark size='64px' color='#2C7865' />

          <ModalBody>
          <br></br>
          <h1 className="form-title">Processo realizado com sucesso!</h1>
          {aux === 1 ? 'Realizamos a convocação dos estudantes para participar do processo seletivo. Gentileza acompanhar a mudança de status da vaga e o e-mail.' :
           aux === 2 ? 'Parabéns pela aprovação do seu novo estagiário. Estamos providenciando os documentos necessários para dar início ao processo de estágio. (Lei n° 11.788)' :''}          
          <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página da Vaga</Button>
          </ModalBody>
      </Modal>

      <Modal isOpen={modal_C2} toggle={toggle_C2} onHide={toggle_C2} centered>

        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C2}>
            <h1 className="form-title">Bem-vindo a aba de estudantes pré-selecionados.</h1>
        </ModalHeader>

          <ModalBody>
          <p className="form-description">Aqui você pode avaliar os currículos e dados dos estudantes que se inscreveram no processo seletivo, chama-los para entrevistas, presenciais ou remotas, e aprovar os estudantes desejados para o seu novo estágio.</p>

          <Button className="login-button" variant="custom" onClick={toggle_C2}>Voltar para a página da Vaga</Button>
          </ModalBody>
      </Modal>


        <Modal isOpen={isConvoqueModalOpen} size='lg' toggle={toggleConvoqueModal}>
        <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleConvoqueModal}></ModalHeader>

            <ModalBody>
                <h1 className="form-title">Enviar e-mail:</h1>
                <br></br>
                <p>Para finalizar o processo a Link Estágio vai criar o contrato para os seguintes estudantes:</p>
                <TabelaEstudantesSelecionados selectedEstudantes={selectedEstudantes} estudantes={estudantes} />
                <p></p>

                <input
                    type="checkbox"
                    className="form-check-input"
                    id="confirmacaoEstudantes" // Adicione o ID se ainda não tiver um
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                />
                <label className="form-check-label ms-1" htmlFor="confirmacaoEstudantes">
                    Confirmo os estudantes selecionados.
                </label>     
                <div className={style.buttonsRight}>
                    <button
                        onClick={CriarContrato}
                        disabled={!isCheckboxChecked} // O botão é desabilitado se isCheckboxChecked for false
                        style={{ backgroundColor: '#eb6c5c', borderColor: '#eb6c5c', color: 'white' }}
                        className={`btn ${style.buttonSpacing}`}
                    >
                        Confirmar
                    </button>

                    <button onClick={toggleConvoqueModal} style={{ backgroundColor: '#7e2553', borderColor: '#7e2553', color: 'white' }} className={`btn ${style.buttonSpacing}`}>
                        Cancelar
                    </button>
                </div>

            </ModalBody>
        </Modal>
        <Modal isOpen={isDeleteModalOpen} size='lg' toggle={toggleDeleteModal}>
        <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleDeleteModal}></ModalHeader>

            <ModalBody>
                <h1 className="form-title">Excluir Estudantes:</h1>
                <br></br>
                <p>Você tem certeza que quer remover os seguintes estudantes do processo seletivo?</p>
                <TabelaEstudantesSelecionados selectedEstudantes={selectedEstudantes} estudantes={estudantes} />
                <p></p>

                <input
                    type="checkbox"
                    className="form-check-input"
                    id="confirmacaoEstudantes" // Adicione o ID se ainda não tiver um
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                />
                <label className="form-check-label ms-1" htmlFor="confirmacaoEstudantes">
                    Confirmo a exclusão dos estudantes do processo seletivo.
                </label>     
                <div className={style.buttonsRight}>
                    <button
                        onClick={RemoverEstudante}
                        disabled={!isCheckboxChecked} // O botão é desabilitado se isCheckboxChecked for false
                        style={{ backgroundColor: '#eb6c5c', borderColor: '#eb6c5c', color: 'white' }}
                        className={`btn ${style.buttonSpacing}`}
                    >
                        Confirmar
                    </button>

                    <button onClick={toggleDeleteModal} style={{ backgroundColor: '#7e2553', borderColor: '#7e2553', color: 'white' }} className={`btn ${style.buttonSpacing}`}>
                        Cancelar
                    </button>
                </div>

            </ModalBody>
        </Modal>
        <Modal isOpen={isInterviewModalOpen} size='lg' toggle={toggleInterviewModal}>
        <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleInterviewModal}>

        <h1 className="form-title">Enviar E-mail</h1>

        </ModalHeader>
        <ModalBody>
        <Form onSubmit={ChamarEntrevista}>
            <p className="form-description">Para agendar a entrevista com o estudante, preencha uma opção abaixo:</p>

            <hr/>
            <h1 className="form-title">Entrevista:</h1>   

            <FormGroup className="radioGroup">
            <FormGroup check>
                <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                    <Input type="radio" name="Periodo_Estagio" checked={periodoEstagio === 'Remoto'} onChange={handleRadioChange} value="Remoto" />{' '}
                    Remoto
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                    <Input type="radio" name="Periodo_Estagio" checked={periodoEstagio === 'Presencial'} onChange={handleRadioChange}  value="Presencial" />{' '}
                    Presencial
                </Label>
            </FormGroup>
        </FormGroup>

        {periodoEstagio === 'Presencial' && (<div>
                
                
                <hr/>
                <Input type="checkbox" onChange={handleCheckboxChangeLocal} checked={isCheckedLocal}/>
                {' '}
                <Label check style={{ fontWeight: '600', color: 'gray'}}>
                Local do Estágio na sede da empresa?
                </Label>
                <br/>



                <Input type="checkbox" onChange={handleCheckboxChangeComplemento} checked={isCheckedComplemento}/>
                {' '}
                <Label check style={{ fontWeight: '600', color: 'gray'}}>
                Endereço tem complemento?
                </Label>    
                <hr/>
                <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Informações para entrevista:</Label>
                <hr/>

                <div className="input-group_">

                <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>Parabéns! Você foi selecionado para entrevista de estágio na empresa {EmpresaInfo.Nome_Empresa}, que vai acontecer às </span>
                <input type="time" name="hora" id="hora" value={inputVaga.Horas} onChange={(e) => setInputVaga({ ...inputVaga, Horas: e.target.value })}    
                style={{
                        width: '100px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Hora"  required />
                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>horas, do dia</span>
                    <input type="number" min={1} max={31} name="dia" id="dia" value={inputVaga.Dia} onChange={(e) => setInputVaga({ ...inputVaga, Dia: e.target.value })}  
                    style={{
                        width: '100px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Dia"  required />
                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>do mês</span>
                    <input type="month" name="mes" id="mes" value={inputVaga.Mes} onChange={(e) => setInputVaga({ ...inputVaga, Mes: e.target.value })}  
                    style={{
                        width: '200px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Mês"  required />
                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>no Endereço:</span>
                    <input type="text" name="rua" id="rua" value={inputVaga.Rua} onChange={(e) => setInputVaga({ ...inputVaga, Rua: e.target.value })}  
                    style={{
                        width: '250px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Nome da Rua ou Avenida"  required />

                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>, Número </span>
                    <input type="text" name="numero" id="numero" value={inputVaga.Numero} onChange={(e) => setInputVaga({ ...inputVaga, Numero: e.target.value })}  
                    style={{
                        width: '60px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="XX"  required />
                    
                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>, </span>
                    <input type="text" name="Cidade" id="Cidade" value={inputVaga.Cidade} onChange={(e) => setInputVaga({ ...inputVaga, Cidade: e.target.value })}  
                    style={{
                        width: '100px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Cidade"  required />
                    
                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>, </span>
                    <input type="text" name="Bairro" id="Bairro" value={inputVaga.Bairro} onChange={(e) => setInputVaga({ ...inputVaga, Bairro: e.target.value })}  
                    style={{
                        width: '100px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Bairro"  required />
                    
                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>, </span>
                    <input type="text" name="Estado" id="Estado" value={inputVaga.Estado} onChange={(e) => setInputVaga({ ...inputVaga, Estado: e.target.value })}  
                    style={{
                        width: '100px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Estado"  required />

                    {isCheckedComplemento && (
                    
                    <>
                    <span for="Complemento" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>, </span>
                    <input type="text" name="complemento" id="complemento" value={inputVaga.Complemento} onChange={(e) => setInputVaga({ ...inputVaga, Complemento: e.target.value })}
                    style={{
                        width: '250px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Complemento" />
                    </>)}
                    <br/>
                    <hr/>
                    <div>
                    <Input type="checkbox" onChange={handleCheckboxOberservacao} checked={isCheckedObservacao}/>
                    {' '}
                    <Label check style={{ fontWeight: '600', color: 'gray'}}>
                    Possui Observação para ser enviada?
                    </Label>
                    {isCheckedObservacao && (
                    <input type="text" name="Obervacao" id="Obervacao" value={inputVaga.Observacao} onChange={(e) => setInputVaga({ ...inputVaga, Observacao: e.target.value })}
                    style={{
                        width: '446px',
                        fontSize: '12px',
                        padding: '4px',
                        marginLeft: '3px'
                        
                    }} placeholder="Observação" />
                    )}
                    </div>
                    <hr/>
                </div>
                <Button className="login-button" variant="custom" type="submit"><strong></strong>Enviar e-mail</Button>

            </div>)}
            {periodoEstagio === 'Remoto' && (<div>



                <hr/>
                <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Informações para entrevista:</Label>
                <hr/>
                <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>Parabéns! Você foi selecionado para entrevista de estágio na empresa {EmpresaInfo.Nome_Empresa}, que vai acontecer às </span>
                <input type="time" name="hora" id="hora" onChange={(e) => setInputVaga2({ ...inputVaga2, Horas: e.target.value })}    
                style={{
                        width: '80px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="hora"  required />
                    <span for="dia" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>horas, do dia</span>
                    <input type="number" min={1} max={31} name="dia" id="dia" onChange={(e) => setInputVaga2({ ...inputVaga2, Dia: e.target.value })}  
                    style={{
                        width: '80px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="dia"  required />
                    <span for="mes" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>do mês</span>
                    <input type="month" name="mes" id="mes" onChange={(e) => setInputVaga2({ ...inputVaga2, Mes: e.target.value })}  
                    style={{
                        width: '200px',
                        height: '30px',
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Mês"  required />
                    <span for="password" className="input-label_" style={{ fontWeight: '600', color: 'gray'}}>. Que acontecerá no link:</span>
                    <input type="text" name="link" id="que acontecerá no link:" onChange={(e) => setInputVaga2({ ...inputVaga2, Link: e.target.value })}  
                    style={{
                        width: '700px',
                        height: '30px',
                        width: '700px',
                        maxWidth: '100%', 
                        fontSize: '12px',
                        padding: '4px',
                        margin: '3px'
                        
                    }} placeholder="Link para Reunião"  required />
                    <br/>
                    <hr/>
                    <div>
                    <Input type="checkbox" onChange={handleCheckboxOberservacao} checked={isCheckedObservacao}/>
                    {' '}
                    <Label check style={{ fontWeight: '600', color: 'gray'}}>
                    Possui Observação para ser enviada?
                    </Label>
                    {isCheckedObservacao && (
                    <input type="text" name="Obervacao" id="Obervacao" value={inputVaga.Observacao} onChange={(e) => setInputVaga({ ...inputVaga, Observacao: e.target.value })}
                    style={{
                        width: '446px',
                        fontSize: '12px',
                        padding: '4px',
                        marginLeft: '3px'
                        
                    }} placeholder="Observação" />
                    )}
                    </div>
                    <hr/>
                    <Button className="login-button" variant="custom" type="submit">Enviar e-mail</Button>

            </div>)}

        </Form>
        </ModalBody>
      </Modal>
        </>
    );
}

export default Estudantes_Candidatados;
