
import style from './estagioLayout.module.css'
import React from "react";
import { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form, Label, FormGroup, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Checkmark } from 'react-checkmark'
import { FaExclamationCircle } from 'react-icons/fa';

import axios from 'axios';

function EstagioLayout() {
    const navigate = useNavigate();
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    

    const [modal_C, setModal_C] = useState(false);
    const toggle_C = () => setModal_C(!modal_C);

    const [modal_C2, setModal_C2] = useState(false);
    const toggle_C2 = () => setModal_C2(!modal_C2);

    const [modal_Confirmar, setModal_Confirmar] = useState(false);

    const [isConvoqueModalOpen, setIsConvoqueModalOpen] = useState(false);
    const [VagaData, setVagaData] = useState({Nome_Empresa:""});


    const handleCandidatura = (VagaData) => {

      if (modal_Confirmar) {
        handleConfirmarModal();
      }else if(isConvoqueModalOpen){
        handleCloseModal();
      }
      setIsConvoqueModalOpen(false);

      const dadosCandidatura = {

          estagio_id: VagaData.idVaga,
          id_Usuario: userInfo.idEstudantes,
          Email_Empresa: VagaData.Email_Empresa,
          Nome_Usuario: userInfo.Nome_Estudante,
          curso: userInfo.curso.Nome_Curso,
          Email_Estudante: userInfo.Email_Estudante,

      };


      axios.post('https://linkestagio.com.br/App.php?operation=cadastro-usuario-vaga1', dadosCandidatura)
        .then((response) => {
          if (typeof response.data === 'string' && response.data.includes('Fatal error') 
          && response.data.includes('Duplicate entry')) {
            toggle_C2();

          }else{
            toggle_C()
            axios.post('https://linkestagio.com.br/App.php?operation=cadastro-usuario-vaga2', dadosCandidatura)
            
            .then((response) => {
            });
          }

        })
        .catch((error) => {
          console.error('Erro ao se candidatar:', error);
        });
  };

  const [modal_S, setModal_S] = useState(false);
  const toggle_S = () => setModal_S(!modal_S);

  const redirectToConfig = () => {
    navigate('/configEstudante');
  };

  useEffect(() => {

    if (userInfo.Curriculo === ""  || userInfo.Curriculo === null) {
      toggle_S()
    }
  }, []);

  const toggle_Confirmar = (idVaga) => {
    const estagioEncontrado = estagioList.find(estagio => estagio.idVaga === idVaga);
    setVagaData(estagioEncontrado);
    setModal_Confirmar(!modal_Confirmar);
  };

  const handleConfirmarModal = () => {
    // Resetar VagaData para o estado inicial ou para um estado vazio/default
    setVagaData({ Nome_Empresa: "" ,Semestre_Inicial: "" ,Semestre_Final: "" ,Carga_Horaria: "" ,
    Atividades: "" ,Remuneracao: "" ,Horario_Estagio: "" ,Periodo_Estagio: "" ,
    Rua: "" ,Numero: "" ,Complemento: "" ,Bairro: "" ,VagaData: "",Email_Empresa:""}); // ou qualquer outro estado inicial que você considerar adequado
    // Fechar o modal
    setModal_Confirmar(false);
  };



  const toggleConvoqueModal = (idVaga) => {
    const estagioEncontrado = estagioList.find(estagio => estagio.idVaga === idVaga);
    setVagaData(estagioEncontrado);

    setIsConvoqueModalOpen(!isConvoqueModalOpen);
  };

  const handleCloseModal = () => {
    // Resetar VagaData para o estado inicial ou para um estado vazio/default
    setVagaData({ Nome_Empresa: "" ,Semestre_Inicial: "" ,Semestre_Final: "" ,Carga_Horaria: "" ,
    Atividades: "" ,Remuneracao: "" ,Horario_Estagio: "" ,Periodo_Estagio: "" ,
    Rua: "" ,Numero: "" ,Complemento: "" ,Bairro: "" ,VagaData: "",Email_Empresa:""}); // ou qualquer outro estado inicial que você considerar adequado

    setIsConvoqueModalOpen(false);
  };
    
  function verificarCEP_Vaga(numero, faixaCEP) {
    const [inicio, fim] = faixaCEP.split('-');
    return parseInt(inicio, 10) <= numero && numero <= parseInt(fim, 10);
}
    
    useEffect(() => {

      const faixasCEP = ['01000-05999', '08000-08499', '06000-09999', '11000-11999', '12000-19999', '20000-23799', '20000-26600', '26601-28999', '29000-29099', '29100-29999', '30000-31999', '30000-34999', '35000-39999', '40000-41999', '40000-44470', '44471-48999', '49000-49099', '49100-49999', '50000-52999', '50000-54999', '55000-56999', '57000-57099', '57100-57999', '58000-58099', '58100-58999', '59000-59099', '59100-59999', '60000-60999', '60000-61900', '61901-63999', '64000-64099', '64100-64999', '65000-65099', '65100-65999', '66000-66999', '66000-67999', '68000-68899', '68900-68914', '68915-68999', '69000-69099', '69100-69299', '69300-69339', '69340-69389', '69900-69920', '69921-69999', '70000-70999', '71000-73699','72800-73999', '74000-74894', '74895-76799', '77000-77270', '77300-77995', '78000-78109', '78110-78899', '78900-78930', '78931-78999', '79000-79129', '79130-79999', '80000-82999', '80000-83800', '83801-87999', '88000-82999', '88000-88469', '88470-89999', '90000-91999', '90000-94900', '94901-99999'];
      var encontrados = ""
      var Semestre_Estudante = "" 

      if (userInfo.CEP_Estudante !== null){
        encontrados = faixasCEP.filter(faixaCEP => verificarCEP_Vaga(parseInt(userInfo.CEP_Estudante.substring(0, 5)), faixaCEP));
        Semestre_Estudante = parseInt(userInfo.Semestre_Estudante)
      }

      axios.post('https://linkestagio.com.br/App.php?operation=get-todas-vagas', {idCurso:userInfo['curso']['idCurso']})
          .then(response => {

            if (encontrados === ""){
              setEstagioList(response.data);

            }else{
            var Vagas_Usuario = []

            for (let i = 0; i < response.data.length; i++) {

              const CEP_Vaga = parseInt(response.data[i]['CEP'].substring(0, 5));

              const Semestre_Inicial = parseInt(response.data[i]['Semestre_Inicial']);
              const Semestre_Final = parseInt(response.data[i]['Semestre_Final']);
              for (let j = 0; j < encontrados.length; j++) {
                  const [inicio, fim] = encontrados[j].split('-');
                  if (parseInt(inicio, 10) <= CEP_Vaga && CEP_Vaga <= parseInt(fim, 10)) {

                    if(Semestre_Estudante <= Semestre_Final && Semestre_Inicial <= Semestre_Estudante){
                      Vagas_Usuario.push(response.data[i]);

                    }else if (isNaN(Semestre_Estudante)) {
                      Vagas_Usuario.push(response.data[i]);

                    }                    
                      break; 
                  }
              }
          }
          setEstagioList(Vagas_Usuario);
            }



          })
          .catch(error => {

            console.error('Erro:', error);
            // Lide com erros aqui, se necessário
          });
      }, []);

    const [estagioList, setEstagioList] = useState([])

    return (
      <>
      
      <div className={style.AreaCardTitle}>
          <p className={style.AreaTitle}>Encontre aqui sua oportunidade de estágio!</p>
      </div>
      <div className={style.AreaCard}>      
        {estagioList.map((estagio) => (
            <div key={estagio.id} >
              <div className={style.cardBody}>
              <p className={`${style.cardTitle}`}>Vaga - N° {parseInt(500) + parseInt(estagio.idVaga, 10)}</p>
                <p className={`${style.cardInfo}`}>Empresa: {estagio.Nome_Empresa}</p>
                <p className={`${style.cardInfo}`}>
                  Bolsa auxílio: R${
                  estagio.Remuneracao 
                  ? parseFloat(estagio.Remuneracao).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  : '0,00'
                }                
                </p>                

                {userInfo.curso.Nome_Curso !== "Ensino Médio" && <p className={`${style.cardInfo}`}>Semestre: {estagio.Semestre_Inicial} ao {estagio.Semestre_Final}</p>}
                <p className={`${style.cardInfo}`}>Período: {estagio.Periodo_Estagio}</p>
                <p className={`${style.cardInfo}`}>Cidade: {estagio.Cidade}</p>
                <p className={`${style.cardInfo}`}>Bairro: {estagio.Bairro}</p>
                <p
                    className={`${style.optionNav}`}  onClick={() => toggleConvoqueModal(estagio.idVaga)}
                >
                    Ver Mais Informações
                </p>
                <p className={`${style.optionNav3}`} onClick={() => toggle_Confirmar(estagio.idVaga)}>Candidatar para Vaga</p>
              </div>
            </div>
          ))}
          </div>
          <Modal isOpen={isConvoqueModalOpen} size='lg' toggle={handleCloseModal}>
          
          <ModalHeader style={{borderBottom: 'none' }} toggle={handleCloseModal}>
            <h1 className="form-title">Informações da Vaga {parseInt(500) + parseInt(VagaData.idVaga, 10)}:</h1>
          </ModalHeader>

            <ModalBody>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Empresa: {VagaData.Nome_Empresa}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Endereço: {VagaData.Rua}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Bairro: {VagaData.Bairro}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Número: {VagaData.Numero}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Complemento: {VagaData.Complemento}</p>
                {userInfo.curso.Nome_Curso !== "Ensino Médio" && <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Semestres Desejados: {VagaData.Semestre_Inicial} ao {VagaData.Semestre_Final}</p>}
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Carga Horária: {VagaData.Carga_Horaria}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Atividades a serem realizadas: {VagaData.Atividades}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Remuneração: R$: {VagaData.Remuneracao}{VagaData.Vale_Alimentacao !== "" && ` + Vale Alimentação (R$: ${VagaData.Vale_Alimentacao})`}{VagaData.Vale_Transporte !== "" && ` + Vale Transporte (R$: ${VagaData.Vale_Transporte})`}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Horário do Estágio: {VagaData.Horario_Estagio}</p>
                <p style={{marginTop: '6px', fontWeight: '600', color: 'black'}}>Periodo: {VagaData.Periodo_Estagio}</p>

              <div className={style.Sidecontainer}>
                <Button className={style.Cancelar} variant="custom" onClick={() => toggle_Confirmar(VagaData.idVaga)}>Candidatar para a Vaga</Button>
               </div>

            </ModalBody>
        </Modal>

        <Modal isOpen={modal_Confirmar} toggle={handleConfirmarModal} className="modal-form">
          <ModalHeader style={{borderBottom: 'none' }} toggle={handleConfirmarModal}>
          <h1 className="form-title">Confirmar Candidatura</h1>

          </ModalHeader>
            <ModalBody>
            <p className="form-description">Você está prestes se candidatar para a vaga {parseInt(500) + parseInt(VagaData.idVaga, 10)} na empresa {VagaData.Nome_Empresa}. 
            Antes de prosseguir, por favor, revise seu curriculo e certifique-se de que todos seus dados foram cadastrados corretamente.</p>
            
            <div className={style.Sidecontainer}>
              <Button className={style.Confirmar} variant="custom" onClick={() => handleCandidatura(VagaData)}>Confirmar</Button>
              <Button className={style.Cancelar} variant="custom" onClick={handleConfirmarModal}>Cancelar</Button>
            </div>

            </ModalBody>
        </Modal>

        <Modal isOpen={modal_S} onHide={() => { toggle_S(); redirectToConfig(); }} centered>
        <ModalHeader style={{ borderBottom: 'none' }} toggle={() => { toggle_S(); redirectToConfig(); }}>
        <h1 className="form-title">Opa, faltam alguns dados serem preenchidos em sua conta</h1>

        </ModalHeader>
          <ModalBody>
          <p className="form-description">Finalize o preenchimento dos seus dados para acessar a pagina de vagas, e não se esqueça de adicionar um currículo.</p>
          <Button className="login-button" variant="custom" onClick={() => { toggle_S(); redirectToConfig(); }}>Ir para o Meu Perfil</Button>
          </ModalBody>
      </Modal>

      <Modal isOpen={modal_C2} toggle={toggle_C2} className="modal-form">
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C2}>

        </ModalHeader>
          <div className="d-flex flex-column align-items-center">
            <FaExclamationCircle size={96} color="#d24e42" />
          </div>

          <ModalBody>
          <br></br>
          <h1 className="form-title">Opa, você já se inscreveu nessa vaga!</h1>

          <p className="form-description">Verifique o seu e-mail para ver notificações do seu processo seletivo.</p>
          <Button className="login-button" variant="custom" onClick={toggle_C2}>Voltar para a página de vagas</Button>
          </ModalBody>
      </Modal>

      <Modal isOpen={modal_C} toggle={toggle_C} className="modal-form">
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C}>

        </ModalHeader>
          <Checkmark size='64px' color='#2C7865' />

          <ModalBody>
          <br></br>
          <h1 className="form-title">Inscrição Realizado Com Sucesso!</h1>
          <p className="form-description">Sua chance de fazer parte de uma equipe que faz a diferença, em uma instituição que oferece não apenas um estágio, mas uma jornada de desenvolvimento pessoal e profissional.</p>
          <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página de vagas</Button>
          </ModalBody>
      </Modal>

      </>
    );

}


export default EstagioLayout;
