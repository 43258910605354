import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const PaginaDetalhesEstagio = () => {
    const location = useLocation();
    const estagio = location.state?.estagio;
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const handleCandidatura = () => {
        const dadosCandidatura = {
            id_Instituicao: userInfo.instituicao_ensino.idInstituicao_Ensino,
            estagio_id: estagio.idVaga,
            id_Usuario: userInfo.idEstudantes
        };

        
        axios.post('https://linkestagio.com.br/App.php?operation=cadastro-usuario-vaga', dadosCandidatura)
          .then((response) => {
            // Aqui você pode adicionar uma lógica após a resposta bem-sucedida
          })
          .catch((error) => {

            console.error('Erro ao se candidatar:', error);

            
          });
    };
    

    if (!estagio) {
        return <div>Informações da vaga não disponíveis.</div>;
    }

    return (
        <div>
            <h1>Detalhes do Estágio</h1>
            {Object.entries(estagio).map(([chave, valor]) => (
                <p key={chave}><strong>{chave.replace(/_/g, ' ')}:</strong> {valor}</p>
            ))}
            <button onClick={handleCandidatura}>Ver Mais Informações</button>
            <button onClick={handleCandidatura}>Candidatar-se à Vaga</button>

        </div>
    );
}

export default PaginaDetalhesEstagio;
