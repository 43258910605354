import React, { useContext, useEffect } from 'react';
import VerticalNavbar from "../../components/verticalHavbarEmpresa/VeticalNavbar2";
import UserConfig from "../../components/empresa/plataforma/userConfig";

function PlataformaEstudante() {

  
    const EmpresaInfo = JSON.parse(sessionStorage.getItem('EmpresaInfo'));
    if (!EmpresaInfo) return <div></div>;
    
    return (      
        <div>
            <VerticalNavbar userInfo={EmpresaInfo} />
            <UserConfig userInfo={EmpresaInfo} />
        </div>
    );
}

export default PlataformaEstudante;
