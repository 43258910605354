
import React, { useState, useRef, useEffect } from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from "reactstrap";
import { NumericFormat } from 'react-number-format';
import Form from 'react-bootstrap/Form';
import { Checkmark } from 'react-checkmark'
import VerticalNavbar from "../../components/verticalHavbarEmpresa/VeticalNavbar2";
import style from './vagacontent.module.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import InputMask from 'react-input-mask'; // Importando react-input-mask
import { useNavigate } from 'react-router-dom';
import { FaExclamationCircle } from 'react-icons/fa';

function VagaContent(args) {
    const navigate = useNavigate();

    const EmpresaInfo = JSON.parse(sessionStorage.getItem('EmpresaInfo'));
    const [cursos, setCursos] = useState([]);
    const [cursosMedio, setCursosMedio] = useState([]);
    const [cursosTecnico, setCursosTecnico] = useState([]);
    const [Nivel_Estudante, setNivel_Estudante] = useState('');
    const [selectedCurso, setSelectedCurso] = useState(null);
    const [inputHoras, setInputHoras] = useState({ Segunda: 0, Terça: 0, Quarta:0,Quinta:0, Sexta: 0, Sábado: 0, Domingo: 0});
    const [inputHorasCheck, setInputHorasCheck] = useState({ Segunda: false, Terça: false, Quarta:false,Quinta:false, Sexta: false, Sábado: false, Domingo: false});
    const [inputHorasSalvar, setInputHorasSalvar] = useState({ Segunda: 'as', Terça: 'as', Quarta:'as',Quinta:'as', Sexta: 'as', Sábado: 'as', Domingo: 'as'});

    const [Valor_Erro_Horas, setValor_Erro_Horas] = useState('');
    const [Valor_Erro_HorasT, setValor_Erro_HorasT] = useState('');


    const [selectedCursos, setSelectedCursos] = useState([]);
    const [estagioList, setEstagioList] = useState([]);
    const chamadaFeitaRef = useRef(false);
    const [isCpfInvalid, setIsCpfInvalid] = useState(false);
    const [isHorarioValid, setIsHorarioValid] = useState(false);

    const [isCnpjInvalid, setIsCnpjInvalid] = useState(false);
    const [isCepInvalid, setIsCepInvalid] = useState(false);
    const [isSupervisorMesmo, setIsSupervisorMesmo] = useState(false);
    const [isLocalEstagioSede, setIsLocalEstagioSede] = useState(false);
    const [modal_C, setModal_C] = useState(false);

    const [errors, setErrors] = useState({
        Quantidade_Estudantes: false,
        Atividades: false,
        Carga_Horaria: false,
        Periodo_Estagio: false,
        Horario_Estagio: false,
        Remuneracao: false,
        Nome_Supervisor_Estagio: false,
        CPF_Supervisor_Estagio: false,
        CNPJ: false,
        CEP_Empresa: false,
        Bairro: false,
        Cursos_Desejados: false,
        Semestre_Inicial: false,
        Semestre_Final: false,
        Nivel_Esudante: false,
    });


    const toggle_C = () => {
        setModal_C(!modal_C);
    };

    const [modal_C2, setModal_C2] = useState(false);

    const toggle_C2 = () => {
        setModal_C2(!modal_C2);
    };


    const handleVale_Refeicao = (e) => {
        setFormData({ ...formData, Vale_Refeicao_C: e.target.checked });
    };

    const handleVale_Transporte = (e) => {
        setFormData({ ...formData, Vale_Transporte_C: e.target.checked });
    };
      
    const handleTimeChange = (day) => (event) => {
        const { name, value: timeValue } = event.target;
        const timeInMinutes = parseInt(timeValue.split(':')[0]) * 60 + parseInt(timeValue.split(':')[1]);
    
        setInputHoras(prevInputHoras => {
            const currentValues = inputHorasSalvar[day].split('as');
            const currentStartTime = currentValues[0] ? parseInt(currentValues[0].split(':')[0]) * 60 + parseInt(currentValues[0].split(':')[1]) : undefined;
            const currentEndTime = currentValues[1] ? parseInt(currentValues[1].split(':')[0]) * 60 + parseInt(currentValues[1].split(':')[1]) : undefined;
    
            // Define startTime e endTime baseado se o evento vem do input de inicio ou fim
            const startTime = name === 'horaInicio' ? timeInMinutes : currentStartTime;
            const endTime = name === 'horaFim' ? timeInMinutes : currentEndTime;
    
            const duration = startTime !== undefined && endTime !== undefined ? endTime - startTime : undefined;
    
            // Atualiza o estado de salvar os horários formatados
            setInputHorasSalvar(prev => {
                if (name === 'horaInicio') {
                    return { ...prev, [day]: `${timeValue} as ${currentValues[1] || ''}` };
                } else {
                    return { ...prev, [day]: `${currentValues[0] || ''} as ${timeValue}` };
                }
            });
    
            return {
                ...prevInputHoras,
                [day]: duration !== undefined ? (duration >= 0 ? duration : -1) : prevInputHoras[day], // Retorna -1 se a duração for negativa
            };
        });
    };


    const handleCheckboxChange = (day) => (event) => {
        const checked = event.target.checked;
        setInputHorasCheck(prev => ({ ...prev, [day]: checked }));
        if (!checked) {
          setInputHoras(prev => ({ ...prev, [day]: 0 }));
          setInputHorasSalvar(prev => ({ ...prev, [day]: 'as' }));
        }
    };
    
      const renderTimeInputs = (day) => {
        if (inputHorasCheck[day]) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', height: '30px' }}>
            <Label style={{ fontWeight: '600', color: 'gray', marginRight: '8px', fontSize: '14px', lineHeight: '30px', marginBottom: '0px' }} check>Início:</Label>
            <input type="time" name="horaInicio" onChange={handleTimeChange(day)} id={`horaInicio${day}`} style={{ width: '80px', height: '30px' }} required />
            <Label style={{ fontWeight: '600', color: 'gray', marginRight: '8px', marginLeft: '8px', fontSize: '14px', lineHeight: '30px', marginBottom: '0px' }} check>Fim:</Label>
            <input type="time" name="horaFim" onChange={handleTimeChange(day)} id={`horaFim${day}`} style={{ width: '80px', height: '30px' }} required />
          </div>
          );
        }
        return null;
      };

    const [formData, setFormData] = useState({
        NomeEmpresa: EmpresaInfo.Nome_Empresa,
        idEmpresa: EmpresaInfo.idEmpresa,
        Email_Empresa: EmpresaInfo.Email_Empresa,
        Quantidade_Estudantes: '',
        Cursos_Desejados: '',
        Cursos_Desejados2: '',
        Cursos_Desejados3: '',
        Cursos_Desejados4: '',
        Semestre_Inicial: '',
        Semestre_Final: '',
        Atividades: '',
        Carga_Horaria: '',
        Remuneracao: '',
        Periodo_Estagio: '',
        Horario_Estagio: '',
        CNPJ: '',
        Nome_Supervisor_Estagio: '',
        CPF_Supervisor_Estagio: '',
        CEP_Empresa: '',
        Estado: '',
        Bairro: '',
        Cidade: '',
        Rua: '',
        Numero: '',
        Complemento: '',
        Vaga_Disponivel: '',
        Vale_Transporte_Valor: '',
        Vale_Refeicao_Valor: ''
    });


    const [Localizacao, setLocalizacao] = useState({
        bairro: '',
        cep: '',
        complemento: '',
        ddd: '',
        gia: '',
        ibge: '',
        localidade: '',
        logradouro: '',
        siafi: '',
        uf: ''
      });

      const handleSupervisorChange = (e) => {
        setIsSupervisorMesmo(e.target.checked);
    
        if (e.target.checked) {

            setFormData(prevFormData => ({
                ...prevFormData,
                Nome_Supervisor_Estagio: EmpresaInfo.Representante_Empresa,
                CPF_Supervisor_Estagio: EmpresaInfo.CPF_Representante,
            }));
        } else {

            setFormData(prevFormData => ({
                ...prevFormData,
                Nome_Supervisor_Estagio: '',
                CPF_Supervisor_Estagio: ''
            }));
        }
    };

    const handleLocaChangeHorario = (e) => {
        setIsLocalEstagioSede(e.target.checked);

        if (e.target.checked) {

        }
    }

    const handleLocalEstagioChange = (e) => {
        setIsLocalEstagioSede(e.target.checked);

        if (e.target.checked) {

            var cep_limpo = EmpresaInfo.CEP_Empresa.replace('-', '').replace(/_/g, '');

            axios.post('https://linkestagio.com.br/App.php?operation=get-localizacao', { CEP: cep_limpo }, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                const data = response.data;
                // Atualiza o formData com os dados de localização recebidos
                setFormData(prevFormData => ({
                    ...prevFormData,
                    Estado: data.uf,
                    Bairro: data.bairro,
                    Cidade: data.localidade,
                    Rua: data.logradouro,
                }));

                setLocalizacao(prevFormData => ({
                    bairro: data.bairro,
                    cep: data.cep,
                    complemento: data.complemento,
                    ddd: data.ddd,
                    gia: data.gia,
                    ibge: data.ibge,
                    localidade: data.localidade,
                    logradouro: data.logradouro,
                    siafi: data.siafi,
                    uf: data.uf
                }));

            })
            .catch(error => {
                console.error('Erro ao buscar dados do CEP:', error);
            });


            setFormData(prevFormData => ({
                ...prevFormData,
                CNPJ: EmpresaInfo.CNPJ,
                CEP_Empresa: EmpresaInfo.CEP_Empresa,
                Numero: EmpresaInfo.Numero,
                Complemento: EmpresaInfo.Complemento_Empresa
            }));



        } else {

            setLocalizacao(prevFormData => ({
                bairro:'',
                cep:'',
                complemento:'',
                ddd:'',
                gia:'',
                ibge:'',
                localidade:'',
                logradouro:'',
                siafi:'',
                uf:''
            }));

            setFormData(prevFormData => ({
                ...prevFormData,
                CNPJ: '',
                CEP_Empresa: '',
                Numero: '',
                Complemento: ''
            }));
        }
    };

    const handleRemoveAllCursos = () => {
        setSelectedCursos([]); // Limpa a lista de cursos selecionados
      
        // Atualiza o formData para limpar os campos relacionados aos cursos
        const clearedCursoFormData = {
          ...formData,
          Cursos_Desejados: '',
          Cursos_Desejados2: '',
          Cursos_Desejados3: '',
        };
      
        setFormData(clearedCursoFormData);
      };


      const [EditVaga, setEditVaga] = useState(false);

      const EditVagaToggle = (estagioId) => {
        setEditVaga(!EditVaga);


        // Verifica se o modal está sendo aberto
        if (!EditVaga) {
            // Atualiza formData para preencher todos os campos com "1"

            axios.post('https://linkestagio.com.br/App.php?operation=editar-vaga', { VagaId: estagioId }, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                const data = response.data;

                const primeirocurso = cursos.find(curso => curso.idCurso  ===  (data.Cursos_Desejados));
                const segundocurso = cursos.find(curso => curso.idCurso  ===  (data.Cursos_Desejados2));
                const terceirocurso = cursos.find(curso => curso.idCurso  ===  (data.Cursos_Desejados3));

                if (primeirocurso !== undefined) {
                    selectedCursos.push(primeirocurso);
                }else{
                    const primeirocurso = cursosTecnico.find(curso => curso.idCurso  ===  (data.Cursos_Desejados));
                    if (primeirocurso !== undefined) {
                        selectedCursos.push(primeirocurso);

                    }
                }
                if (segundocurso !== undefined) {
                    selectedCursos.push(segundocurso);
                }else{
                    const segundocurso = cursosTecnico.find(curso => curso.idCurso  ===  (data.Cursos_Desejados2));
                    if (segundocurso !== undefined) {
                        selectedCursos.push(segundocurso);

                    }
                }

                if (terceirocurso !== undefined) {
                    selectedCursos.push(terceirocurso);
                }else{
                    const terceirocurso = cursosTecnico.find(curso => curso.idCurso  ===  (data.Cursos_Desejados3));
                    if (terceirocurso !== undefined) {
                        selectedCursos.push(terceirocurso);

                    }
                }

                if(data.Vale_Transporte != ""){
                    setFormData({ ...formData, Vale_Transporte_C: true });
                }

                if(data.Vale_Alimentacao != ""){
                    setFormData({ ...formData, Vale_Refeicao_C: true });
                }



                setFormData(formData => ({
                    ...formData,
                    Quantidade_Estudantes: data.Quantidade_Estudantes,
                    Semestre_Inicial: data.Semestre_Inicial,
                    Semestre_Final: data.Semestre_Final,
                    Atividades: data.Atividades,
                    Carga_Horaria: data.Carga_Horaria,
                    Horario_Estagio: data.Horario_Estagio,
                    Remuneracao: data.Remuneracao,
                    CNPJ: data.CNPJ,
                    Nome_Supervisor_Estagio: data.Nome_Supervisor_Estagio,
                    CPF_Supervisor_Estagio: data.CPF_Supervisor_Estagio,
                    Numero: data.Numero,
                    Periodo_Estagio: data.Periodo_Estagio,
                    Complemento: data.Complemento,
                    Cursos_Desejados: data.Cursos_Desejados,
                    Cursos_Desejados2: data.Cursos_Desejados2,
                    Cursos_Desejados3: data.Cursos_Desejados3,
                    CEP_Empresa: data.CEP,
                    Estado: data.Estado,
                    Bairro: data.Bairro,
                    Cidade: data.Cidade,
                    Rua: data.Rua,
                    Complemento: data.Complemento,
                    VagaId: estagioId,
                    Vaga_Disponivel: data.Vaga_Disponivel,
                    Vale_Transporte_Valor: data.Vale_Transporte,
                    Vale_Refeicao_Valor: data.Vale_Alimentacao

                }));   
                

                setLocalizacao({
                    cep: data.CEP,
                    bairro: data.Bairro,
                    localidade: data.Cidade,
                    logradouro: data.Rua,
                    uf: data.Estado
                });


            })
            .catch(error => {
                console.error('Erro ao buscar dados do CEP:', error);
            });

        }else{
            setSelectedCursos([]);
        }
    };


    function validaCNPJ(cnpj) {
        cnpj = formData['CNPJ']

        cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
        if (cnpj.length !== 14) return false; // Verifica o tamanho
      
        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        const digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(0))) return false;
      
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(1))) return false;
      
        return true;
      }

    function validaCPF(cpf) {
    cpf = formData['CPF_Supervisor_Estagio'];
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false; // Verifica o tamanho e sequências iguais
    
    let soma = 0;
    for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
    let resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9))) return false;
    
    soma = 0;
    for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(10))) return false;
    
    return true;
    }

    const EditVagaSubmit = (estagioId, event) => {

        var Erro = 0;


        if (!validaCPF(formData['CPF_Representante'])) {
            setIsCpfInvalid(true);
            Erro += 1;
        } else {
            setIsCpfInvalid(false);
        }
    
        if (!validaCNPJ(formData['CNPJ'])) {
          setIsCnpjInvalid(true);
          Erro += 1;
        } else {
          setIsCnpjInvalid(false);
        }

        if (isCepInvalid){
            Erro += 1;
        }


        const requiredFields = [
            'Quantidade_Estudantes',
            'Atividades',
            'Carga_Horaria',
            'Periodo_Estagio',
            'Remuneracao',
            'Horario_Estagio',
            'Nome_Supervisor_Estagio',
            'CPF_Supervisor_Estagio',
            'CNPJ',
            'Bairro',
            'Cursos_Desejados',
            'Nivel_Esudante',
            'CEP_Empresa'
            ];  



        if (parseInt(formData.Cursos_Desejados, 10) !== 188 && (formData['Semestre_Inicial'] === '' && formData['Semestre_Final'] === '')){
            errors['Semestre_Inicial'] = true;
            errors['Semestre_Final'] = true;
            Erro += 1;
        }else{
            errors['Semestre_Inicial'] = false;
            errors['Semestre_Final'] = false;   
        }
          
        const invalidFields = requiredFields.filter(field => {
            const value = String(formData[field]);
            return !value || value.trim() === '';
            });




        invalidFields.forEach(field => {
            errors[field] = true;
            });


        const isEveryFieldValid = invalidFields.length === 0;

        if (!isEveryFieldValid) {

            Erro += 1;

          }

        if(Erro != 0){
            setValor_Erro_HorasT("Opa, alguns campos parecem estar incorretos.")
            setValor_Erro_Horas("Corrija todos os campos sinalizados antes de cadastrar a sua vaga.")
            setIsHorarioValid(true);
            toggle_C2();
            handleChange({ target: { name: 'c', value: 'c' } });
            return;
        }


        if(Object.values(inputHoras).some(value => value === -1)){
            setIsHorarioValid(true);
            setValor_Erro_HorasT("Opa, ajuste a carga horária para finalizar o cadastro da vaga.")
            setValor_Erro_Horas("Algum horário que você cadastrou para a vaga parace estar inválido.")
            toggle_C2();
            return;

        }

        const diasAtivos = Object.entries(inputHorasCheck)
        .filter(([day, isChecked]) => isChecked) // Filtra apenas os dias marcados como verdadeiros
        .map(([day]) => day); // Extrai os dias

        const soma = diasAtivos.reduce((acc, day) => {


        if (!inputHorasSalvar[day].endsWith('as')) { // Verifica se o valor não termina com 'as'
        // Assume que o valor a somar está corretamente armazenado como um número em inputHoras
        return acc + inputHoras[day];
        }
        return acc;
        }, 0);

        const valores = Object.values(inputHoras);
        const temValorMaiorQue360 = valores.some(valor => valor > 360);

        var numeroDeHoras = parseInt(formData['Carga_Horaria'].replace("hrs", ""));
        var minutos = numeroDeHoras * 60;


        if( soma > minutos || temValorMaiorQue360){
            setValor_Erro_HorasT("Opa, ajuste a carga horária para finalizar o cadastro da vaga.")
            setValor_Erro_Horas("A jornada de trabalho do estudante não pode ultrapassar a carga horária máximo: 6 (seis) horas diárias e 30 horas semanais. De acordo com a Lei 11.788/2008.")
            setIsHorarioValid(true);
            toggle_C2();

            return;
        }

        if (formData.Horario_Estagio !== 'Definir') {
            formData.Complemento = ""; 
            formData.Horario_Estagio = "A definir"
        }else{
            const entradasFiltradas = Object.entries(inputHorasSalvar).filter(([key, value]) => !value.endsWith('as'));

            // Mapeia as entradas filtradas para a forma de string "Dia: Horário"
            const formatado = entradasFiltradas.map(([key, value]) => `${key}: ${value}`);
        
            // Junta todas as entradas formatadas com ' / ' entre elas
            const resultado = formatado.join(' / ');
            formData.Horario_Estagio = resultado.replace(/\s+/g, ' ').trim()

        }

        if (formData.Complemento === null) {
            formData.Complemento = "";  // Atribuir uma string vazia se for null
        }


        // Executar a requisição dentro deste callback garante que ela use o estado atualizado
        axios.post('https://linkestagio.com.br/App.php?operation=update-vaga', formData)
            .then((response) => {
                if (response.data !== null) {
                    window.location.reload();
                } else {
                    console.error('Erro de login:', response.data);
                }
            })
            .catch((error) => {
                console.error('Erro de login:', error);
            });

        return formData;


    };


      const [infoVaga, setInfoVaga] = useState(false);

      const infoVagaToggle = (estagioId) => {

        navigate('/Estudantes_Candidatados', { state: { estagio: estagioId} });


    // Importante: Retornar o estado atualizado para que ele seja efetivamente atualizado.
        return estagioId;

      }

      const handleChange = (event) => {
        const { name, value } = event.target;

        
        
        var cep_limpo = value.replace('-', '').replace(/_/g, '');

        if (name === "CEP_Empresa" && cep_limpo.length === 8) {

            axios.post('https://linkestagio.com.br/App.php?operation=get-localizacao', { CEP: cep_limpo }, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {


                const data = response.data;
        

                if (!('erro' in response.data)) {
                    
                    setIsCepInvalid(false);

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        Estado: data.uf,
                        Bairro: data.bairro,
                        Cidade: data.localidade,
                        Rua: data.logradouro
                    }));
    
                    setLocalizacao(prevFormData => ({
                        bairro: data.bairro,
                        cep: data.cep,
                        complemento: data.complemento,
                        ddd: data.ddd,
                        gia: data.gia,
                        ibge: data.ibge,
                        localidade: data.localidade,
                        logradouro: data.logradouro,
                        siafi: data.siafi,
                        uf: data.uf
                    }));
        
                  }else{
                    
                    setIsCepInvalid(true);

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        Estado: '',
                        Bairro: '',
                        Cidade: '',
                        Rua: ''
                    }));
    
                    setLocalizacao(prevFormData => ({
                        bairro: '',
                        cep: '',
                        complemento: '',
                        ddd: '',
                        gia: '',
                        ibge: '',
                        localidade: '',
                        logradouro: '',
                        siafi: '',
                        uf: ''
                    }));

                  }


            })
            .catch(error => {
                console.error('Erro ao buscar dados do CEP:', error);
            });
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleAddCurso = () => {
        const newCurso = { idCurso: '', Nome_Curso: '' };
        setSelectedCursos([...selectedCursos, newCurso]);
      };
      
      const handleRemoveCurso = (index) => {
        const newCursos = selectedCursos.filter((_, idx) => idx !== index);
        setSelectedCursos(newCursos);
      };

      const handleCursoSelect = (index, curso) => {
        const newSelectedCursos = [...selectedCursos];
        newSelectedCursos[index] = curso; // Atualiza o curso selecionado no índice correto
    
        setSelectedCursos(newSelectedCursos); // Atualiza o estado dos cursos selecionados
    
        // Atualiza o formData com os novos valores dos cursos selecionados
        const cursoFormData = {
            ...formData,
            Cursos_Desejados: newSelectedCursos[0] ? newSelectedCursos[0].idCurso : '',
            Cursos_Desejados2: newSelectedCursos[1] ? newSelectedCursos[1].idCurso : '',
            Cursos_Desejados3: newSelectedCursos[2] ? newSelectedCursos[2].idCurso : '',
        };
    
        setFormData(cursoFormData);
    };

    const [modal, setModal] = useState(false);

    useEffect(() => {
        // Faça a chamada à API para obter os dados dos cursos
        axios.get('https://linkestagio.com.br/App.php?operation=get-cursos')
        .then(response => {
          if (Array.isArray(response.data)) {
            const cursosFaculdade = [];
            const cursosTecnicoTemp = [];
            const cursosMedioTemp = [];
  
            response.data.forEach(curso => {
              switch (curso.Tipo_Curso) {
                case 'Faculdade':
                  cursosFaculdade.push(curso);
                  break;
                case 'Técnico':
                  cursosTecnicoTemp.push(curso);
                  break;
                case 'Ensino Médio':
                  cursosMedioTemp.push(curso);
                  break;
                default:
                  // Cursos que não se encaixam nas categorias conhecidas
                  break;
              }
            });
  
            // Atualiza os estados com os cursos filtrados por tipo
            setCursos(cursosFaculdade);
            setCursosTecnico(cursosTecnicoTemp);
            setCursosMedio(cursosMedioTemp);
          }
        })
          .catch(error => {
            console.error('Erro:', error);
            // Lide com erros aqui, se necessário
          });
      }, []);

      useEffect(() => {
        if (!chamadaFeitaRef.current) {
            axios.post('https://linkestagio.com.br/App.php?operation=get-vagas-empresa-abertas', EmpresaInfo)
                .then((response) => {
                    const enrichedEstagioList = response.data.map(estagio => ({
                        ...estagio,
                        Nome_Empresa: EmpresaInfo.Nome_Empresa,
                        Telefone_Empresa: EmpresaInfo.Telefone_Empresa,
                        Remuneracao: estagio.Remuneracao
                    }));

                    setEstagioList(enrichedEstagioList);

                })
                .catch(error => {
                    console.error('Erro:', error);
                });
            chamadaFeitaRef.current = true; // Marca que a chamada foi feita
        }
    }, [EmpresaInfo]); 

    
    const handleSubmit = (event) => {
        var Erro = 0;
        
        if (!validaCPF(formData['CPF_Representante'])) {
            setIsCpfInvalid(true);
            Erro += 1;
        } else {
            setIsCpfInvalid(false);
        }
    
        if (!validaCNPJ(formData['CNPJ'])) {
          setIsCnpjInvalid(true);
          Erro += 1;
        } else {
          setIsCnpjInvalid(false);
        }

        if (isCepInvalid){
            Erro += 1;
        }

        const requiredFields = [
            'Quantidade_Estudantes',
            'Atividades',
            'Carga_Horaria',
            'Periodo_Estagio',
            'Horario_Estagio',
            'Remuneracao',
            'Nome_Supervisor_Estagio',
            'CPF_Supervisor_Estagio',
            'CNPJ',
            'Bairro',
            'Cursos_Desejados',
            'Nivel_Esudante',
            'CEP_Empresa'
            ];  

        if (Nivel_Estudante !== 'Médio' && (formData['Semestre_Inicial'] === '' || formData['Semestre_Final'] === '')){
            errors['Semestre_Inicial'] = true;
            errors['Semestre_Final'] = true;
            Erro += 1;
        }else if(Nivel_Estudante !== 'Médio' && (parseInt(formData['Semestre_Inicial']) > parseInt(formData['Semestre_Final']))){
            errors['Semestre_Final'] = true;   
            Erro += 1;

        } else{
            errors['Semestre_Inicial'] = false;
            errors['Semestre_Final'] = false;   
        }

        console.log(parseInt(formData['Semestre_Inicial']))
        console.log(parseInt(formData['Semestre_Final']));
          
        const invalidFields = requiredFields.filter(field => {
            const value = String(formData[field]);
            return !value || value.trim() === '';
            });


        invalidFields.forEach(field => {
            errors[field] = true;
            });




        const isEveryFieldValid = invalidFields.length === 0;

        if (!isEveryFieldValid) {
            Erro += 1;

          }

          


        if(Erro != 0){
            setValor_Erro_HorasT("Opa, alguns campos parecem estar incorretos.")
            setValor_Erro_Horas("Corrija todos os campos sinalizados antes de cadastrar a sua vaga.")
            setIsHorarioValid(true);
            toggle_C2();
            handleChange({ target: { name: 'c', value: 'c' } });
            return;
        }

        if((Object.values(inputHoras).some(value => value === -1)) || (formData.Horario_Estagio !== "A Definir" && Object.values(inputHoras).every(value => value === 0))){
            setIsHorarioValid(true);
            setValor_Erro_HorasT("Opa, ajuste a carga horária para finalizar o cadastro da vaga.")
            setValor_Erro_Horas("Algum horário que você cadastrou para a vaga parace estar inválido.")
            toggle_C2();
            return;

        }

        const diasAtivos = Object.entries(inputHorasCheck)
        .filter(([day, isChecked]) => isChecked) // Filtra apenas os dias marcados como verdadeiros
        .map(([day]) => day); // Extrai os dias

        const soma = diasAtivos.reduce((acc, day) => {

        if (!inputHorasSalvar[day].endsWith('as')) { // Verifica se o valor não termina com 'as'
        // Assume que o valor a somar está corretamente armazenado como um número em inputHoras
        return acc + inputHoras[day];
        }
        return acc;
        }, 0);

        const valores = Object.values(inputHoras);
        const temValorMaiorQue360 = valores.some(valor => valor > 360);

        var numeroDeHoras = parseInt(formData['Carga_Horaria'].replace("hrs", ""));
        var minutos = numeroDeHoras * 60;

        if( soma > minutos || temValorMaiorQue360){
            setValor_Erro_HorasT("Opa, ajuste a carga horária para finalizar o cadastro da vaga.")
            setValor_Erro_Horas("A jornada de trabalho do estudante não pode ultrapassar a carga horária máximo: 6 (seis) horas diárias e 30 horas semanais. De acordo com a Lei 11.788/2008.")
            setIsHorarioValid(true);
            toggle_C2();
            return;
        }
        
        toggle();
        

        if (formData.Horario_Estagio !== 'Definir') {
            formData.Complemento = ""; 
            formData.Horario_Estagio = "A definir"
        }else{

            const entradasFiltradas = Object.entries(inputHorasSalvar).filter(([key, value]) => !value.endsWith('as'));

            // Mapeia as entradas filtradas para a forma de string "Dia: Horário"
            const formatado = entradasFiltradas.map(([key, value]) => `${key}: ${value}`);
        
            // Junta todas as entradas formatadas com ' / ' entre elas
            const resultado = formatado.join(' / ');
            formData.Horario_Estagio = resultado.replace(/\s+/g, ' ').trim()

        }

        if (formData.Complemento === null) {
            formData.Complemento = "";  // Atribuir uma string vazia se for null
        }

        
        setTimeout(() => {
            toggle_C(true);
        }, 450);
        
        axios.post('https://linkestagio.com.br/App.php?operation=cadastrar-vaga1', formData)
          .then((response) => {
            if (response.data) {
                setTimeout(() => {
                    toggle_C(true);
                }, 450);

                
                axios.post('https://linkestagio.com.br/App.php?operation=cadastrar-vaga2', formData)
                .then((response) => {
                });
                
                
            } else {
              console.error('Erro de login:', response.data.message);
            }
          })
          .catch((error) => {
            console.error('Erro de login:', error);
          });
          
      };


    if (!EmpresaInfo) return <div></div>;


    const toggle = () => {
        // Atualiza o estado do modal para abrir ou fechar
        setModal(!modal);
    
        // Redefine o formData sempre que o modal é aberto ou fechado
        setFormData({
            NomeEmpresa: EmpresaInfo.Nome_Empresa, // Mantém o valor atual de NomeEmpresa
            idEmpresa: EmpresaInfo.idEmpresa, // Mantém o valor atual de idEmpresa
            Email_Empresa: EmpresaInfo.Email_Empresa,
            Quantidade_Estudantes: '',
            Cursos_Desejados: '',
            Cursos_Desejados2: '',
            Cursos_Desejados3: '',
            Vale_Transporte_Valor: '',
            Vale_Refeicao_Valor: '',
            Semestre_Inicial: '',
            Semestre_Final: '',
            Atividades: '',
            Carga_Horaria: '',
            Remuneracao: '',
            Periodo_Estagio: '',
            Horario_Estagio: '',
            CNPJ: '',
            Nome_Supervisor_Estagio: '',
            CPF_Supervisor_Estagio: '',
            CEP: '',
            Estado: '',
            Bairro: '',
            Cidade: '',
            Rua: '',
            Numero: '',
            Complemento: ''
        });
    
        // Adicionalmente, se você quiser limpar os cursos selecionados, pode fazer isso também
        setSelectedCursos([]);
    };

    return (
        <>

            <Modal isOpen={modal_C} toggle={() => window.location.reload()} centered>
            <ModalHeader style={{ borderBottom: 'none' }} toggle={() => window.location.reload()}>

            </ModalHeader>
                <Checkmark size='64px' color='#2C7865' />

                <ModalBody>
                <br></br>
                <h1 className="form-title">Vaga Cadastrada Com Sucesso!</h1>
                <p className="form-description">Sua Vaga foi cadastrada com sucesso! Por ela você pode ver os estudantes que se inscreveram no processo seletivo, ver seus currículos e chama-los para entrevista e quando decidir os estudantes a Link Estágio vai preparar o contrato de estágio e enviar por e-mail.</p>
                <Button className="login-button" variant="custom" onClick={() => window.location.reload()}>Voltar para a página de Vagas.</Button>
                </ModalBody>
            </Modal>

            <VerticalNavbar userInfo={EmpresaInfo} />
            <div className={`${style.contentVaga}`}>
                <div className={`${style.minhasVagas}`}>
                <p style={{ marginRight: '30px', fontSize: '30px', color: '#7e2553', fontWeight: '600'}}>Minhas Vagas</p>
                    <div>
                    <p
                        className={`${style.optionNav}`} onClick={toggle}
                    >
                        Cadastrar vaga
                    </p>
            
                    <div className="modal-columns">
                        <Modal isOpen={modal} toggle={toggle} size="lg" {...args}>

                            <ModalHeader toggle={toggle}>Adicionar uma Nova Vaga de Estágio</ModalHeader>
                            <Form onSubmit={handleSubmit}  className="modal-form">
                                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                            <div className="input-block">
                                
                            <h1 className="form-title">Dados do Estágio</h1>   
                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Quantidade de Vagas</Label>
                            <Input name = "Quantidade_Estudantes" invalid={errors.Quantidade_Estudantes} id="Quantidade_Estudantes" type="number" className={`form-control ${errors.Quantidade_Estudantes ? 'is-invalid' : ''}`} placeholder="Quantidade de vagas"  min='1' onChange={handleChange}  />
                            </div>

                            <Label   style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="Nivel_Esudante">Nivel de Escolaridade</Label>
                            <FormGroup className="radioGroup" invalid={errors.Nivel_Esudante}>
                            <FormGroup required check>
                                <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                    <Input type="radio" name="Nivel_Esudante" value="Ensino Médio"  onChange={() => {
                                        setNivel_Estudante('Médio');
                                        setFormData(prevState => ({
                                            ...prevState,
                                            Cursos_Desejados: 188
                                          }));

                                    }} checked={Nivel_Estudante === 'Médio'} />{' '}
                                    Ensino Médio
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                    <Input type="radio" name="Nivel_Esudante" value="Ensino Técnico"  onChange={() => {
                                        setNivel_Estudante('Técnico');
                                        setSelectedCurso(null);
                                        handleRemoveAllCursos()

                                    }} checked={Nivel_Estudante === 'Técnico'} />{' '}
                                    Ensino Técnico
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                    <Input type="radio" name="Nivel_Esudante" value="Graduação" onChange={() => {

                                        setNivel_Estudante('Faculdade');
                                        setSelectedCurso(null);
                                        handleRemoveAllCursos()

                                    }} checked={Nivel_Estudante === 'Faculdade'} />{' '}
                                    Ensino Superior
                                </Label>
                            </FormGroup>
                            </FormGroup>

                            {Nivel_Estudante === 'Faculdade' && (
                            <>

                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Cursos Desejados</Label>
                            <p className="form-description">Escolha até 3 cursos diferentes</p>
                            <FormGroup >
                                <div className="input-block">
                                    <Autocomplete
                                    disablePortal
                                    id="Cursos_Desejados_0"
                                    options={cursos}
                                    getOptionLabel={(option) => option.Nome_Curso || ""}
                                    value={selectedCursos[0] || {}}
                                    onChange={(event, value) => handleCursoSelect(0, value)}
                                    renderInput={(params) => <TextField {...params} label="Curso 1"  />}
                                    
                                    />
                                </div>

                                {selectedCursos.slice(1).map((curso, index) => (
                                    <div key={index + 1} className="input-block">
                                    <Autocomplete
                                        disablePortal
                                        id={`Cursos_Desejados_${index + 1}`}
                                        options={cursos}
                                        getOptionLabel={(option) => option.Nome_Curso || ""}
                                        value={curso}
                                        onChange={(event, value) => handleCursoSelect(index + 1, value)}
                                        renderInput={(params) => <TextField {...params} label={`Curso ${index + 2}`}  />}
                                        sx={{ paddingTop: '6px' }} // Adiciona padding-top de 10px

                                    />
                                    <Button
                                        color="danger"
                                        className={style.Remove_Button}
                                        style={{ marginTop: '10px' }}
                                        onClick={() => handleRemoveCurso(index + 1)}
                                    >
                                        Remover Curso
                                    </Button>
                                    </div>
                                ))}

                                {/* Botão para adicionar o segundo e terceiro curso */}
                                {selectedCursos.length < 3 && (
                                    <Button
                                    color="primary"
                                    className={style.Add_Button}
                                    onClick={handleAddCurso}
                                    >
                                    Adicionar outro Curso
                                    </Button>
                                )}
                                </FormGroup>

                                <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Semestres Desejados</Label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '5px' }}>
                                    
                                    <Input name = "Semestre_Inicial"  type="select" id="Semestre_Inicial" invalid={errors.Semestre_Inicial} value={formData['Semestre_Inicial']} placeholder="" onChange={handleChange}>
                                    <option value="" disabled>Selecione</option>
                                    {[...Array(10)].map((_, index) => (
                                        <option key={index} value={index + 1}>{index + 1}</option>
                                    ))}
                                    </Input>
                                    <span>ao</span>
                                    <Input name = "Semestre_Final"  type="select" id="Semestre_Final" invalid={errors.Semestre_Final} value={formData['Semestre_Final']} placeholder="" onChange={handleChange}>
                                    <option value="" disabled>Selecione</option>
                                    {[...Array(10)].map((_, index) => (
                                        <option key={index} value={index + 1}>{index + 1}</option>
                                    ))}
                                    </Input>
                                </div>

                            </>
                            )}


                            {Nivel_Estudante === 'Técnico' && (
                            <>

                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Cursos Desejados</Label>
                            <p className="form-description">Escolha até 3 cursos diferentes</p>
                            <FormGroup>
                                <div className="input-block">
                                    <Autocomplete
                                    disablePortal
                                    id="Cursos_Desejados_0"
                                    options={cursosTecnico}
                                    getOptionLabel={(option) => option.Nome_Curso || ""}
                                    value={selectedCursos[0] || {}}
                                    onChange={(event, value) => handleCursoSelect(0, value)}
                                    renderInput={(params) => <TextField {...params} label="Curso 1"  />}
                                    
                                    />
                                </div>

                                {selectedCursos.slice(1).map((curso, index) => (
                                    <div key={index + 1} className="input-block">
                                    <Autocomplete
                                        disablePortal
                                        id={`Cursos_Desejados_${index + 1}`}
                                        options={cursosTecnico}
                                        getOptionLabel={(option) => option.Nome_Curso || ""}
                                        value={curso}
                                        onChange={(event, value) => handleCursoSelect(index + 1, value)}
                                        renderInput={(params) => <TextField {...params} label={`Curso ${index + 2}`}  />}
                                        sx={{ paddingTop: '6px' }} // Adiciona padding-top de 10px

                                    />
                                    <Button
                                        color="danger"
                                        className={style.Remove_Button}
                                        style={{ marginTop: '10px' }}
                                        onClick={() => handleRemoveCurso(index + 1)}
                                    >
                                        Remover Curso
                                    </Button>
                                    </div>
                                ))}

                                {/* Botão para adicionar o segundo e terceiro curso */}
                                {selectedCursos.length < 3 && (
                                    <Button
                                    color="primary"
                                    className={style.Add_Button}
                                    onClick={handleAddCurso}
                                    >
                                    Adicionar outro Curso
                                    </Button>
                                )}
                                </FormGroup>

                                <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Anos Desejados</Label>
                                    
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '5px' }}>
                                    
                                    <Input name = "Semestre_Inicial" type="select" id="Semestre_Inicial" invalid={errors.Semestre_Inicial} placeholder="" value={formData['Semestre_Inicial']} onChange={handleChange}>
                                    <option value="" disabled>Selecione</option>
                                    {[...Array(3)].map((_, index) => (
                                        <option key={index} value={index + 1}>{index + 1} Ano</option>
                                    ))}
                                    </Input>
                                    <span>ao</span>
                                    <Input name = "Semestre_Final" id="Semestre_Final" invalid={errors.Semestre_Final}  placeholder="" value={formData['Semestre_Final']} type='select' min='1' onChange={handleChange}>
                                    <option value="" disabled>Selecione</option>
                                    {[...Array(3)].map((_, index) => (
                                        <option key={index} value={index + 1} >{index + 1} Ano</option>
                                    ))}
                                    </Input>
                                </div>

                            </>
                            )}



                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Atividades Realizadas pelo Estagiário</Label>
                            <div className="input-block">
                            <textarea name = "Atividades"  invalid={errors.Semestre_Final} className={`form-control ${errors.Atividades ? 'is-invalid' : ''}`} id="Atividades" placeholder="Atividades" onChange={handleChange}  />
                            </div>

                            <Label for="Carga_Horaria" style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Carga Horária Semanal</Label>

                            <div className="input-block">

                            <select name="Carga_Horaria" className={`form-select ${errors.Carga_Horaria ? 'is-invalid' : ''}`} id="Carga_Horaria" onChange={handleChange} value={formData.Carga_Horaria}>
                                <option value="">Selecione</option>
                                <option value="20hrs">20 Horas</option>
                                <option value="25hrs">25 Horas</option>
                                <option value="30hrs">30 Horas</option>
                            </select>
                            </div>

                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Período do Estágio</Label>

                            <FormGroup className="radioGroup">
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio" name="Periodo_Estagio" value="Manhã" onChange={handleChange} checked={formData.Periodo_Estagio === 'Manhã'} />{' '}
                                        Manhã
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio" name="Periodo_Estagio" value="Tarde" onChange={handleChange} checked={formData.Periodo_Estagio === 'Tarde'} />{' '}
                                        Tarde
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio" name="Periodo_Estagio" value="Noite" onChange={handleChange} checked={formData.Periodo_Estagio === 'Noite'} />{' '}
                                        Noite
                                    </Label>
                                </FormGroup>
                            </FormGroup>




                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553' }}>Horário do Estágio</Label>

                            <FormGroup className="radioGroup">
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio"  name="Horario_Estagio" value="Definir" onChange={handleChange} checked={formData.Horario_Estagio === 'Definir'} />{' '}
                                        Definir Horário
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-inicio">O horário de estágio será exibido como "A definir" para o estudante e será necessário defini-lo ao criar o contrato</Tooltip>}
                                >
                                    <span className="d-inline-block">
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>Sem Horário Definido
                                        <Input type="radio" name="Horario_Estagio" value="A Definir" onChange={handleChange} checked={formData.Horario_Estagio === 'A Definir'} />{' '}
                                    </Label>
                                    </span>
                                </OverlayTrigger>
                                </FormGroup>
                            </FormGroup>


                            {formData.Horario_Estagio === 'Definir' && (
                            <>
                            <Label style={{ fontWeight: '600', color: '#7e2553', marginRight: '8px' }}>Dias</Label>

                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Segunda')}
                                checked={inputHorasCheck['Segunda']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Segunda</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Segunda')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Terça')}
                                checked={inputHorasCheck['Terca']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Terça</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Terça')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Quarta')}
                                checked={inputHorasCheck['Quarta']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Quarta</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Quarta')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Quinta')}
                                checked={inputHorasCheck['Quinta']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Quinta</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Quinta')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Sexta')}
                                checked={inputHorasCheck['Sexta']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Sexta</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Sexta')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Sábado')}
                                checked={inputHorasCheck['Sábado']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Sábado</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Sábado')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Domingo')}
                                checked={inputHorasCheck['Domingo']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Domingo</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Domingo')}
                            </Form.Group>
                            </>
                            )}
                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="Remuneracao">Remuneração</Label>
                            <div className="input-block">
                            <NumericFormat
                                id="Remuneracao"
                                name="Remuneracao"
                                className={`form-control ${errors.Remuneracao ? 'is-invalid' : ''}`}
                                prefix="R$"
                                placeholder="R$00,00"
                                decimalScale={2}
                                thousandSeparator='.'
                                decimalSeparator=','
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={formData.Remuneracao}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    // Chamada do handleChange para atualizar o estado com o nome e valor
                                    handleChange({ target: { name: 'Remuneracao', value } });
                                }}
                            />
                            </div>
                            <Form.Check
                                type="checkbox"
                                name="Vale_Transporte"
                                id="Vale_Transporte"
                                onChange={handleVale_Transporte}
                                checked={formData.Vale_Transporte_C}
                                style={{ display: 'flex', alignItems: 'center', height: '50px', width: '340px', fontWeight: '600', color: 'gray', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Vale transporte</span>}
                            />
                                {formData.Vale_Transporte_C && (
                                    <div className="input-block">
                                    <NumericFormat
                                        id="Vale_Transporte_Valor"
                                        name="Vale_Transporte_Valor"
                                        className="form-control"
                                        prefix="R$"
                                        placeholder="R$00,00"
                                        decimalScale={2}
                                        thousandSeparator='.'
                                        decimalSeparator=','
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={formData.Vale_Transporte_Valor}
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            // Chamada do handleChange para atualizar o estado com o nome e valor
                                            handleChange({ target: { name: 'Vale_Transporte_Valor', value } });
                                        }}
                                    />
                                    </div>
                                )}
                                <Form.Check
                                type="checkbox"
                                name="Vale_Refeicao"
                                id="Vale_Refeicao"
                                onChange={handleVale_Refeicao}
                                checked={formData.Vale_Refeicao_C}
                                style={{ display: 'flex', alignItems: 'center', height: '50px', width: '320px', marginBottom:'10px', fontWeight: '600', color: 'gray', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Vale refeicão</span>}
                                />
                                {formData.Vale_Refeicao_C && (
                                    <div className="input-block">
                                    <NumericFormat
                                        id="Vale_Refeicao_Valor"
                                        name="Vale_Refeicao_Valor"
                                        className={`form-control`}
                                        prefix="R$"
                                        placeholder="R$00,00"
                                        decimalScale={2}
                                        thousandSeparator='.'
                                        decimalSeparator=','
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={formData.Vale_Refeicao_Valor}
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            // Chamada do handleChange para atualizar o estado com o nome e valor
                                            handleChange({ target: { name: 'Vale_Refeicao_Valor', value } });
                                        }}
                                    />
                                    </div>
                                )}
                            

                                        
                        <h1 className="form-title">Dados da Empresa</h1>   

                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Supervisor</Label>
                        
                        <Form.Group className="custom-checkbox-wrapper" controlId="formPrivacyCheckbox">
                            <Form.Check type="checkbox"  onChange={handleSupervisorChange} checked={isSupervisorMesmo} style={{ fontWeight: '600', color: 'gray'}} label="Mesmo Supervisor Cadastrado?"  />
                        </Form.Group>

                        <div className="input-block">
                        <Input name = "Nome_Supervisor_Estagio" className={`form-control ${errors.Nome_Supervisor_Estagio ? 'is-invalid' : ''}`} id="Nome_Supervisor_Estagio"  placeholder="Nome do Supervisor" value={formData.Nome_Supervisor_Estagio} onChange={handleChange}/>
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="CPF_Supervisor_Estagio">CPF do Supervisor</Label>
                            {isCpfInvalid && (
                                <div style={{ color: 'red', marginBottom: '10px' }}>
                                    CPF inválido.
                                </div>
                            )}
                            <div className="input-block">
                                <InputMask
                                    mask="999.999.999-99"
                                    className={`form-control ${isCpfInvalid ? 'is-invalid' : ''}`}
                                    name="CPF_Supervisor_Estagio"
                                    id="CPF_Supervisor_Estagio"
                                    
                                    placeholder="CPF do Supervisor"
                                    onChange={handleChange}
                                    value={formData.CPF_Supervisor_Estagio}
                                >
                                    {(inputProps) => (
                                        <input {...inputProps} />
                                    )}
                                </InputMask>    
                            </div>



                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Local do Estágio</Label><br></br>

                        <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa">
                            <Form.Check type="checkbox" onChange={handleLocalEstagioChange} checked={isLocalEstagioSede} style={{ fontWeight: '600', color: 'gray'}} label="Local do estágio é na Sede da Empresa?"/>
                        </Form.Group>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="CNPJ">CNPJ da Empresa</Label>
                        {isCnpjInvalid && (
                            <div style={{ color: 'red', marginBottom: '10px' }}>
                                CNPJ inválido.
                            </div>
                        )}
                        <div className="input-block">
                            <InputMask
                                mask="99.999.999/9999-99"
                                className={`form-control ${errors.CEP_Empresa ? 'is-invalid' : ''}`}
                                name="CNPJ"
                                id="CNPJ"
                                placeholder="CNPJ da Empresa"
                                onChange={handleChange}
                                value={formData.CNPJ}
                            >
                                {(inputProps) => (
                                    <input {...inputProps} />
                                )}
                            </InputMask>    
                        </div>
                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="CEP_Empresa">CEP</Label>
                            <div className="input-block">
                            <InputMask
                                mask="99999-999"
                                value={formData.CEP_Empresa || ''}
                                onChange={handleChange}
                                className={`form-control linkInput ${errors.CEP_Empresa ? 'is-invalid' : ''}`}
                                id="CEP_Empresa"
                                name="CEP_Empresa"
                                placeholder="CEP da Sede"
                                type="text"
                                required
                            />
                            </div>                                
                        
                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="uf">Estado</Label>
                        <div className="input-block">
                            <Input type="text" id="uf" name="uf" placeholder="Estado" value={Localizacao.uf} onChange={handleChange} disabled />
                        </div>
                        
                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="bairro">Bairro</Label>
                        <div className="input-block">
                        <Input type="text" id="bairro" name="bairro" placeholder="Bairro" value={Localizacao.bairro} onChange={handleChange} disabled />
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="localidade">Cidade</Label>
                        <div className="input-block">
                            <Input type="text" id="localidade" name="localidade" placeholder="Cidade" value={Localizacao.localidade} onChange={handleChange} disabled />
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}} for="logradouro">Rua</Label>
                        <div className="input-block">
                            <Input type="text" id="logradouro" name="logradouro" placeholder="Rua" value={Localizacao.logradouro} onChange={handleChange} disabled />
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Número</Label>
                        <div className="input-block">
                            <Input name = "Numero" id="Numero" value={formData.Numero} placeholder="Número" onChange={handleChange} />
                        </div>
                        
                        <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Complemento</Label>
                        <div className="input-block">
                            <Input name = "Complemento" id="Complemento" value={formData.Complemento || ""} placeholder="Complemento" onChange={handleChange}/>
                        </div>

                        </ModalBody>
                        <ModalFooter>
                        <p
                            className={`${style.optionNav2}`} onClick={handleSubmit}
                        >
                            Confirmar
                        </p>{' '}
                        <p
                            className={`${style.optionNav}`} onClick={toggle}
                        >
                            Cancelar
                        </p>{' '}
                        </ModalFooter>
                        </Form>
                    </Modal>
                </div>
            </div>
        </div>
        </div>
            <div className={style.AreaCard}>      
                {estagioList.map((estagio) => (
                    <div key={estagio.idVaga} >
                        <div className={style.cardBody}>
                        <p className={`${style.cardTitle}`}>Vaga - N° {500 + parseInt(estagio.idVaga, 10)}</p>
                        <p className={style.cardInfo}>
                        Status: 
                        <span className={(() => {
                        switch(estagio.Vaga_Disponivel) {
                            case 'Aberta': return style.statusAberta;
                            case 'Pausada': return style.statusPausada;
                            case 'Fechada': return style.statusFechada;
                            default: return 'statusFechada';
                        }
                        })()}>{estagio.Vaga_Disponivel}</span></p>
                        <p className={`${style.cardInfo}`}>
                        Bolsa auxílio: R${
                        estagio.Remuneracao 
                        ? parseFloat(estagio.Remuneracao).toLocaleString('pt-BR', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                            })
                        : '0,00'
                        }                
                        </p> 
                        {estagio.Semestre_Inicial !== '' && (
                        <p className={style.cardInfo}>Semestre/Ano: {estagio.Semestre_Inicial} ao {estagio.Semestre_Final}</p>
                        )}
                        {estagio.Semestre_Inicial === '' && (
                        <p className={style.cardInfo}>Ensino Médio</p>
                        )}
                        <p className={style.cardInfo}>Período do Estágio: {estagio.Periodo_Estagio}</p>
                        <p className={style.cardInfo}>Cidade: {estagio.Cidade}</p>
                        <p className={style.cardInfo}>Bairro: {estagio.Bairro}</p>

                        <p
                            className={`${style.optionNav}`} onClick={() => EditVagaToggle(estagio.idVaga)}
                        >
                            Editar Vaga
                        </p>
                        <p
                            className={`${style.optionNav3}`} onClick={() => infoVagaToggle(estagio.idVaga)}
                        >
                            Verificar Estudantes
                        </p>

                <div>

                    <div className="modal-columns">
                        <Modal isOpen={EditVaga} toggle={EditVagaToggle} size="lg">
                        <Form>
                        <ModalHeader className=" " toggle={EditVagaToggle}>Editar Vaga</ModalHeader>

                            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>


                            <div className="input-block">
                                <h1 className="form-title">Status da vaga</h1>   

                                <select name="Vaga_Disponivel" id="Vaga_Disponivel" className="form-select" onChange={handleChange} value={formData.Vaga_Disponivel}>
                                    <option value="Aberta">Aberta</option>
                                    <option value="Pausada">Pausada</option>
                                    <option value="Fechada">Fechada</option>
                                </select>
                            </div>
                            <p className="form-description">Para atualizar os dados da vaga é necessário colocar os horários de estágio desejados novamente</p>


                            <div className="input-block">

                            {cursos.some(curso => curso.idCurso === formData.Cursos_Desejados) && (
                            <>
                            <br></br> 
                            <h1 className="form-title">Perfil de estudante para vaga: Ensino Superior</h1>  
                            <br></br>                             
                            </>
                            )}

                            {cursosTecnico.some(curso => curso.idCurso === formData.Cursos_Desejados) && (
                            <>
                            <br></br> 
                            <h1 className="form-title">Perfil de estudante para vaga: Ensino Técnico</h1>  
                            <br></br>                             
                            </>
                            )}

                            {formData.Cursos_Desejados === 188 && (
                            <>
                            <br></br> 
                            <h1 className="form-title">Perfil de estudante para vaga: Ensino Médio</h1>  
                            <br></br> 
                            </>
                            )}

                            <h1 className="form-title">Dados do Estágio</h1>   
                            <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Quantidade de Vagas</Label>
                            <Input name = "Quantidade_Estudantes" className={`form-control ${errors.Quantidade_Estudantes ? 'is-invalid' : ''}`} id="Quantidade_Estudantes" type="number" value={formData.Quantidade_Estudantes} placeholder="Quantidade de vagas" min='1' onChange={handleChange}  />
                            </div>


                            {cursos.some(curso => curso.idCurso === formData.Cursos_Desejados) && (
                            <>

                            <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Cursos Desejados</Label>
                            <p className="form-description">Escolha até 3 cursos diferentes</p>
                            <FormGroup>
                                <div className="input-block">
                                    <Autocomplete
                                    disablePortal
                                    id="Cursos_Desejados_0"
                                    options={cursos}
                                    getOptionLabel={(option) => option.Nome_Curso || ""}
                                    value={selectedCursos[0] || {}}
                                    onChange={(event, value) => handleCursoSelect(0, value)}
                                    renderInput={(params) => <TextField {...params} label="Curso 1"  />}
                                    />
                                </div>

                                {selectedCursos.slice(1).map((curso, index) => (
                                    <div key={index + 1} className="input-block">
                                    <Autocomplete
                                        disablePortal
                                        id={`Cursos_Desejados_${index + 1}`}
                                        options={cursos}
                                        getOptionLabel={(option) => option.Nome_Curso || ""}
                                        value={curso}
                                        onChange={(event, value) => handleCursoSelect(index + 1, value)}
                                        renderInput={(params) => <TextField {...params} label={`Curso ${index + 2}`}  />}
                                        sx={{ paddingTop: '8px' }} // Adiciona padding-top de 10px

                                    />
                                    <Button
                                        color="danger"
                                        className={style.Remove_Button}
                                        style={{ marginTop: '10px' }}
                                        onClick={() => handleRemoveCurso(index + 1)}
                                    >
                                        Remover Curso
                                    </Button>
                                    </div>
                                ))}

                                {selectedCursos.length < 3 && (
                                    <Button
                                    color="primary"
                                    className={style.Add_Button}
                                    onClick={handleAddCurso}
                                    >
                                    Adicionar outro Curso
                                    </Button>
                                )}
                                </FormGroup>

                            </>
                            )}

                            {cursosTecnico.some(curso => curso.idCurso === formData.Cursos_Desejados) && (
                            <>

                            <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Cursos Desejados</Label>
                            <p className="form-description">Escolha até 3 cursos diferentes</p>
                            <FormGroup>
                                <div className="input-block">
                                    <Autocomplete
                                    disablePortal
                                    id="Cursos_Desejados_0"
                                    options={cursosTecnico}
                                    getOptionLabel={(option) => option.Nome_Curso || ""}
                                    value={selectedCursos[0] || {}}
                                    onChange={(event, value) => handleCursoSelect(0, value)}
                                    renderInput={(params) => <TextField {...params} label="Curso 1"  />}
                                    />
                                </div>

                                {selectedCursos.slice(1).map((curso, index) => (
                                    <div key={index + 1} className="input-block">
                                    <Autocomplete
                                        disablePortal
                                        id={`Cursos_Desejados_${index + 1}`}
                                        options={cursosTecnico}
                                        getOptionLabel={(option) => option.Nome_Curso || ""}
                                        value={curso}
                                        onChange={(event, value) => handleCursoSelect(index + 1, value)}
                                        renderInput={(params) => <TextField {...params} label={`Curso ${index + 2}`}  />}
                                        sx={{ paddingTop: '8px' }} // Adiciona padding-top de 10px

                                    />
                                    <Button
                                        color="danger"
                                        className={style.Remove_Button}
                                        style={{ marginTop: '10px' }}
                                        onClick={() => handleRemoveCurso(index + 1)}
                                    >
                                        Remover Curso
                                    </Button>
                                    </div>
                                ))}

                                {selectedCursos.length < 3 && (
                                    <Button
                                    color="primary"
                                    className={style.Add_Button}
                                    onClick={handleAddCurso}
                                    >
                                    Adicionar outro Curso
                                    </Button>
                                )}
                                </FormGroup>

                            </>
                            )}
                            {cursos.some(curso => curso.idCurso === formData.Cursos_Desejados, 10) && (
                            <>
                            <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Semestres Desejados</Label>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '5px' }}>
                                    
                                    <Input name = "Semestre_Inicial"   type="select" value={formData.Semestre_Inicial} id="Semestre_Inicial" placeholder="" onChange={handleChange}>
                                    <option value="" disabled>Selecione</option>
                                    {[...Array(10)].map((_, index) => (
                                        <option key={index} value={index + 1}>{index + 1}</option>
                                    ))}
                                    </Input>
                                    <span>ao</span>
                                    <Input name = "Semestre_Final"  id="Semestre_Final" value={formData.Semestre_Final} placeholder="Final" type='select' min='1' onChange={handleChange}>
                                    <option value="" disabled>Selecione</option>
                                    {[...Array(10)].map((_, index) => (
                                        <option key={index} value={index + 1}>{index + 1}</option>
                                    ))}
                                    </Input>
                                </div>
                                </>
                                )}

                            {cursosTecnico.some(curso => curso.idCurso === formData.Cursos_Desejados, 10) && (
                            <>
                                <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553'}}>Anos Desejados</Label>
                                    
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '5px' }}>
                                        
                                        <Input name = "Semestre_Inicial" value={formData.Semestre_Inicial} type="select" id="Semestre_Inicial" placeholder="" onChange={handleChange}>
                                        <option value="" disabled>Selecione</option>
                                        {[...Array(3)].map((_, index) => (
                                            <option key={index} value={index + 1}>{index + 1} Ano</option>
                                        ))}
                                        </Input>
                                        <span>ao</span>
                                        <Input name = "Semestre_Final" value={formData.Semestre_Final} id="Semestre_Final" placeholder="Final" type='select' min='1' onChange={handleChange}>
                                        <option value="" disabled>Selecione</option>
                                        {[...Array(3)].map((_, index) => (
                                            <option key={index} value={index + 1} >{index + 1} Ano</option>
                                        ))}
                                        </Input>
                                    </div>
    
                                </>
                                )}

                            <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Atividades Realizadas pelo Estagiário</Label>
                            <div className="input-block">
                            <textarea name = "Atividades" className={`form-control ${errors.Atividades ? 'is-invalid' : ''}`} id="Atividades" placeholder="Atividades" value={formData.Atividades} onChange={handleChange}  />
                            </div>

                            <Label for="Carga_Horaria" style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Carga Horária Semanal</Label>

                            <div className="input-block">
                                <select name="Carga_Horaria" className={`form-select ${errors.Carga_Horaria ? 'is-invalid' : ''}`} id="Carga_Horaria" onChange={handleChange} value={formData.Carga_Horaria}>
                                    <option value="">Selecione</option>
                                    <option value="20hrs">20 Horas</option>
                                    <option value="25hrs">25 Horas</option>
                                    <option value="30hrs">30 Horas</option>
                                </select>
                            </div>

                            <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Período do Estágio</Label>

                            <FormGroup className="radioGroup">
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio" name="Periodo_Estagio" value="Manhã" onChange={handleChange} checked={formData.Periodo_Estagio === 'Manhã'} />{' '}
                                        Manhã
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio" name="Periodo_Estagio" value="Tarde" onChange={handleChange} checked={formData.Periodo_Estagio === 'Tarde'} />{' '}
                                        Tarde
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio" name="Periodo_Estagio" value="Noite" onChange={handleChange} checked={formData.Periodo_Estagio === 'Noite'} />{' '}
                                        Noite
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            
                            
                            <Label style={{marginTop: '10px', fontWeight: '600', color: '#7e2553' }}>Horário do Estágio</Label>
                            <FormGroup className="radioGroup">
                                <FormGroup check>
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                                        <Input type="radio"  name="Horario_Estagio" value="Definir" onChange={handleChange} checked={formData.Horario_Estagio === 'Definir'} />{' '}
                                        Definir Horário
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-inicio">O horário de estágio será exibido como "A definir" para o estudante e será necessário defini-lo ao criar o contrato</Tooltip>}
                                >
                                    <span className="d-inline-block">
                                    <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>Sem Horário Definido
                                    <Input type="radio" name="Horario_Estagio" value="A Definir" onChange={handleChange} checked={formData.Horario_Estagio === 'A Definir'} />{' '}
                                    </Label>
                                    </span>
                                </OverlayTrigger>
                                </FormGroup>
                            </FormGroup>


                            {formData.Horario_Estagio === 'Definir' && (
                            <>
                            <Label style={{ fontWeight: '600', color: '#7e2553', marginRight: '8px' }}>Dias</Label>

                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Segunda')}
                                checked={inputHorasCheck['Segunda']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Segunda</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Segunda')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Terça')}
                                checked={inputHorasCheck['Terça']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Terça</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Terça')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Quarta')}
                                checked={inputHorasCheck['Quarta']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Quarta</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Quarta')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Quinta')}
                                checked={inputHorasCheck['Quinta']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Quinta</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Quinta')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Sexta')}
                                checked={inputHorasCheck['Sexta']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Sexta</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Sexta')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Sábado')}
                                checked={inputHorasCheck['Sábado']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Sábado</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Sábado')}
                            </Form.Group>
                            <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '6px', marginBottom: '6px' }}>
                            <Form.Check
                                type="checkbox"
                                onChange={handleCheckboxChange('Domingo')}
                                checked={inputHorasCheck['Domingo']}
                                style={{display: 'flex', alignItems: 'center',height: '30px', width: '120px', fontWeight: '600', color: 'gray', marginRight: '8px', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Domingo</span>} // Adicionado marginLeft para criar espaço
                            />
                            {renderTimeInputs('Domingo')}
                            </Form.Group>
                            </>
                            )}

                            <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="Remuneracao">Remuneração</Label>
                            <div className="input-block">
                            <NumericFormat
                                id="Remuneracao"
                                name="Remuneracao"
                                className={`form-control ${errors.Remuneracao ? 'is-invalid' : ''}`}
                                prefix="R$"
                                placeholder="R$00,00"
                                decimalScale={2}
                                thousandSeparator='.'
                                decimalSeparator=','
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={formData.Remuneracao}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    // Chamada do handleChange para atualizar o estado com o nome e valor
                                    handleChange({ target: { name: 'Remuneracao', value } });
                                }}
                            />
                            </div>
                            <Form.Check
                                type="checkbox"
                                name="Vale_Transporte"
                                id="Vale_Transporte"
                                onChange={handleVale_Transporte}
                                checked={formData.Vale_Transporte_C}
                                style={{ display: 'flex', alignItems: 'center', height: '50px', width: '340px', fontWeight: '600', color: 'gray', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Vale transporte</span>}
                            />
                                {formData.Vale_Transporte_C && (
                                    <div className="input-block">
                                    <NumericFormat
                                        id="Vale_Transporte_Valor"
                                        name="Vale_Transporte_Valor"
                                        className="form-control"
                                        prefix="R$"
                                        placeholder="R$00,00"
                                        decimalScale={2}
                                        thousandSeparator='.'
                                        decimalSeparator=','
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={formData.Vale_Transporte_Valor}
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            // Chamada do handleChange para atualizar o estado com o nome e valor
                                            handleChange({ target: { name: 'Vale_Transporte_Valor', value } });
                                        }}
                                    />
                                    </div>
                                )}
                                <Form.Check
                                type="checkbox"
                                name="Vale_Refeicao"
                                id="Vale_Refeicao"
                                onChange={handleVale_Refeicao}
                                checked={formData.Vale_Refeicao_C}
                                style={{ display: 'flex', alignItems: 'center', height: '50px', width: '320px', marginBottom:'10px', fontWeight: '600', color: 'gray', marginBottom: '0px' }}
                                label={<span style={{ marginLeft: '10px' }}>Vale refeicão</span>}
                                />
                                {formData.Vale_Refeicao_C && (
                                    <div className="input-block">
                                    <NumericFormat
                                        id="Vale_Refeicao_Valor"
                                        name="Vale_Refeicao_Valor"
                                        className={`form-control`}
                                        prefix="R$"
                                        placeholder="R$00,00"
                                        decimalScale={2}
                                        thousandSeparator='.'
                                        decimalSeparator=','
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={formData.Vale_Refeicao_Valor}
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            // Chamada do handleChange para atualizar o estado com o nome e valor
                                            handleChange({ target: { name: 'Vale_Refeicao_Valor', value } });
                                        }}
                                    />
                                    </div>
                                )}
                        <h1 className="form-title">Dados da Empresa</h1>   

                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Supervisor</Label>
                        
                        <Form.Group className="custom-checkbox-wrapper" controlId="formPrivacyCheckbox">
                            <Form.Check type="checkbox" onChange={handleSupervisorChange} checked={isSupervisorMesmo} style={{ fontWeight: '600', color: 'gray'}} label="Mesmo Supervisor Cadastrado?"  />
                        </Form.Group>

                        <div className="input-block">
                        <Input name = "Nome_Supervisor_Estagio" className={`form-control ${errors.Nome_Supervisor_Estagio ? 'is-invalid' : ''}`} value={formData.Nome_Supervisor_Estagio} id="Nome_Supervisor_Estagio"  placeholder="Nome do Supervisor" onChange={handleChange}/>
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="CPF_Supervisor_Estagio">CPF do Supervisor</Label>
                            {isCpfInvalid && (
                                <div style={{ color: 'red', marginBottom: '10px' }}>
                                    CPF inválido.
                                </div>
                            )}
                            <div className="input-block">
                                <InputMask
                                    mask="999.999.999-99"
                                    className={`form-control ${isCpfInvalid ? 'is-invalid' : ''}`}
                                    name="CPF_Supervisor_Estagio"
                                    id="CPF_Supervisor_Estagio"
                                    placeholder="CPF do Supervisor"
                                    onChange={handleChange}
                                    value={formData.CPF_Supervisor_Estagio}
                                >
                                    {(inputProps) => (
                                        <input {...inputProps} />
                                    )}
                                </InputMask>    
                            </div>



                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Local do Estágio</Label><br></br>

                        <Form.Group className="custom-checkbox-wrapper" controlId="SedeEmpresa">
                            <Form.Check type="checkbox" onChange={handleLocalEstagioChange} checked={isLocalEstagioSede} style={{ fontWeight: '600', color: 'gray'}} label="Local do estágio é na Sede da Empresa?"/>
                        </Form.Group>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="CNPJ">CNPJ da Empresa</Label>
                        {isCnpjInvalid && (
                            <div style={{ color: 'red', marginBottom: '10px' }}>
                                CNPJ inválido.
                            </div>
                        )}
                        <div className="input-block">
                            <InputMask
                                mask="99.999.999/9999-99"
                                className={`form-control ${isCnpjInvalid ? 'is-invalid' : ''}`}
                                name="CNPJ"
                                id="CNPJ"
                                placeholder="CNPJ da Empresa"
                                onChange={handleChange}
                                value={formData.CNPJ}
                            >
                                {(inputProps) => (
                                    <input {...inputProps} />
                                )}
                            </InputMask>    
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="CEP_Empresa">CEP</Label>
                        <div className="input-block">
                            <InputMask
                                mask="99999-999"
                                value={formData.CEP_Empresa || ''}
                                onChange={handleChange}
                                className={`form-control linkInput ${isCepInvalid ? 'is-invalid' : ''}`}
                                id="CEP_Empresa"
                                name="CEP_Empresa"
                                placeholder="CEP da Sede"
                                type="text"
                                required
                            />                        
                        </div>                                
                        
                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="uf">Estado</Label>
                        <div className="input-block">
                            <Input type="text" id="uf" name="uf" placeholder="Estado" value={Localizacao.uf} onChange={handleChange} disabled />
                        </div>
                        
                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="bairro">Bairro</Label>
                        <div className="input-block">
                        <Input type="text" id="bairro" name="bairro" placeholder="Bairro" value={Localizacao.bairro} onChange={handleChange} disabled />
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="localidade">Cidade</Label>
                        <div className="input-block">
                            <Input type="text" id="localidade" name="localidade" placeholder="Cidade" value={Localizacao.localidade} onChange={handleChange} disabled />
                        </div>

                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} for="logradouro">Rua</Label>
                        <div className="input-block">
                            <Input type="text" id="logradouro" name="logradouro" placeholder="Rua" value={Localizacao.logradouro} onChange={handleChange} disabled />
                        </div>   

                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Número</Label>
                        <div className="input-block">
                            <Input name = "Numero" id="Numero"  placeholder="Número" value={formData.Numero} onChange={handleChange} />
                        </div>
                        
                        <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}}>Complemento</Label>
                        <div className="input-block">
                            <Input name = "Complemento" id="Complemento" value={formData.Complemento} placeholder="Complemento" onChange={handleChange}/>
                        </div>
                        </ModalBody>
                        <ModalFooter>
                        <p
                            className={`${style.optionNav2}`} onClick={(event) => EditVagaSubmit(estagio.idVaga, event)} value={formData}
                        >
                            Editar Vaga
                        </p>{' '}
                        <p
                            className={`${style.optionNav}`} onClick={EditVagaToggle}
                        >
                            Cancelar
                        </p>{' '}
                        </ModalFooter>
                        </Form>
                    </Modal>
                    </div>
                </div>
            </div>
        </div>
        ))}
    </div>   

    <Modal isOpen={modal_C2} toggle={toggle_C2} className="modal-form">
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C2}>

        </ModalHeader>
          <div className="d-flex flex-column align-items-center">
            <FaExclamationCircle size={96} color="#d24e42" />
          </div>

          <ModalBody>
          <br></br>
          <h1 className="form-title">{Valor_Erro_HorasT}</h1>

          <p className="form-description">{Valor_Erro_Horas}</p>
          <Button className="login-button" variant="custom" onClick={toggle_C2}>Fechar</Button>
          </ModalBody>
      </Modal>         
    </>
    );
}

export default VagaContent;
