import React, { useContext, useEffect,useState } from 'react';
import UserContext from '../../UserContext.js';
import VerticalNavbar from "../../components/verticalHavbar/VeticalNavbar2";
import UserConfig from "../../components/estudante/plataforma/userConfig";

function PlataformaEstudante() {

    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const [isDadosPreenchidos, setDadosPreenchidos] = useState(false);

    const DadosPreenchidoCheck = (dados) => {
        setDadosPreenchidos(dados);
    };

    if (!userInfo) return <div></div>;
    
    return (      
        <div>
            <VerticalNavbar userInfo={userInfo} DadosPreenchidos={isDadosPreenchidos} />
            <UserConfig userInfo={userInfo} DadosPreenchidosChecker={DadosPreenchidoCheck} />
        </div>
    );
}

export default PlataformaEstudante;
