import React, { useContext, useEffect } from 'react';
import UserContext from '../../UserContext.js';
import {Label, Form, FormGroup, Input} from 'reactstrap';


import VerticalNavbar from "../../components/verticalHavbar/VeticalNavbar2";

import UserConfig from "../../components/estudante/plataforma/userConfig";

import EstagioLayout from "../../components/estudante/plataforma/estagioLayout";


function Perfil_Estudante() {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));


    if (!userInfo) return <div>Carregando...</div>;
    
    return (
                
        <div>
            <VerticalNavbar userInfo={userInfo} />
            <EstagioLayout />
        </div>
    )
}

export default Perfil_Estudante;